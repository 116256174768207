import React, { useState, useEffect, useRef } from "react";
import './NhsAutocompleteSuggestionsV2.scss';

const NhsAutocompleteSuggestionsV2 = ({ inputValue, suggestions, handleOnClick, ...props }) => {
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const optionRefs = useRef([]);
    const autocompleteRef = useRef(null);

    useEffect(() => {
        setHighlightedIndex(-1);
        optionRefs.current = optionRefs.current.slice(0, suggestions.length);
    }, [suggestions]);

    const handleKeyDown = (event) => {
        const { key } = event;

        if (["ArrowDown", "ArrowUp", "Tab", "Enter"].includes(key)) {
            event.preventDefault();
        }

        switch (key) {
            case 'ArrowDown':
            case 'Tab':
                if (!event.shiftKey) {
                    setHighlightedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
                }
                break;
            case 'ArrowUp':
                if (!event.shiftKey) {
                    setHighlightedIndex((prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length);
                }
                break;
            case 'Enter':
                if (highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
                    handleOnClick(props.name, suggestions[highlightedIndex]);
                }

                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
            optionRefs.current[highlightedIndex]?.focus();
        }
    }, [highlightedIndex, suggestions]);

    const renderSuggestions = () => {
        if (suggestions.length === 0) {
            return (
                <li aria-selected="false" aria-disabled="true">
                    No organisations found
                </li>
            );
        }

        return suggestions.map((suggestion, index) => (
            <li
                key={index}
                aria-selected={highlightedIndex === index}
                className={`autocomplete__option ${index % 2 !== 0 ? 'autocomplete__option--odd' : ''} ${highlightedIndex === index ? 'autocomplete__option--highlighted' : ''}`}
                id={`organisationCode__option--${index}`}
                role="option"
                tabIndex={0}
                ref={el => optionRefs.current[index] = el}
                aria-posinset={index + 1}
                aria-setsize={suggestions.length}
                onClick={() => handleOnClick(props.name, suggestion)}
            >
                <HighlightMatch suggestion={suggestion.name} inputValue={inputValue} />
            </li>
        ));
    };

    return (
        <div
            ref={autocompleteRef}
            onKeyDown={handleKeyDown}
            role="combobox"
            aria-expanded={suggestions.length > 0}
            aria-owns="organisationCode__listbox"
            aria-haspopup="listbox"
        >
            {suggestions && (
                <ul id="organisationCode__listbox" role="listbox" className="autocomplete__menu">
                    {renderSuggestions()}
                </ul>
            )}
        </div>
    );
};

const HighlightMatch = ({ suggestion, inputValue }) => {
    if (!suggestion || !inputValue) return <>{suggestion}</>;

    const normalizedSuggestion = suggestion.toLowerCase();
    const normalizedInputValue = inputValue.toLowerCase();
    const index = normalizedSuggestion.indexOf(normalizedInputValue);

    if (index === -1) return <>{suggestion}</>;

    const beforeMatch = suggestion.slice(0, index);
    const match = suggestion.slice(index, index + inputValue.length);       
    const afterMatch = suggestion.slice(index + inputValue.length);

    return (
        <>
            {beforeMatch}
            <strong>{match}</strong>
            {afterMatch}
        </>
    );
};

export default NhsAutocompleteSuggestionsV2;
