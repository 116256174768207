import * as React from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import { number, object } from 'yup';
import { Button } from 'reactstrap';
import useDocumentTitle from '../../_shared/hooks/useDocumentTitle';
import useAnalytics from '../analytics/hooks/useAnalytics';
import { AddBatchAnalyticsSubCategories, DepleteBatchAnalyticsSubCategories, SiteVaccinesAnalyticsPageNames, SiteVaccinesPageTitles, SiteVaccinesPaths } from './site-vaccines.enums';
import { HandleErrorFocusOnClickByName, HandleFocusChange, Redirect, RemoveTime } from '../../_shared/shared.functions';
import { Paths } from '../../_shared/shared.enums';
import { SiteVaccines } from './site-vaccines.models';
import { BatchDto } from '../batch/batch.models';
import siteVaccinesService from './site-vaccines.service';

export default function DepleteBatch() {
    const location = useLocation();
    const navigate = useNavigate();

    const redirect = () => {
        Redirect(Paths.Home);
    }

    const optionsStateData = location && location.state ? location.state[0] as any : redirect();
    const userSitesStateData = location && location.state ? location.state[1] as any : redirect();
    const siteVaccinesStateData = location && location.state ? location.state[2] as SiteVaccines[] : redirect();
    const batchStateData = location && location.state ? location.state[3] as BatchDto : redirect();
    const siteVaccineBatchesStateData = location && location.state ? location.state[4] as BatchDto[] : redirect();
    const addOrEditStateData = location && location.state ? location.state[5] as any : redirect();
    const depleteOrUndepleteStateData = location && location.state ? location.state[6] as any : redirect();
    const siteVaccineStateData = location && location.state ? location.state[7] as SiteVaccines : redirect();

    const [options, setOptions] = React.useState(optionsStateData);
    const [userSites] = React.useState(userSitesStateData);
    const [siteVaccines, setSiteVaccines] = React.useState(siteVaccinesStateData as SiteVaccines[]);
    const [batch, setBatch] = React.useState(batchStateData as BatchDto);
    const [siteVaccine] = React.useState(siteVaccineStateData as SiteVaccines);
    const [batchDepletedDateRequiredError, setBatchDepletedDateRequiredError] = React.useState(false);
    const [batchDepletedDateError, setBatchDepletedDateError] = React.useState(false);
    const [futureExpiryDateError, setFutureExpiryDateError] = React.useState(false);

    useDocumentTitle(SiteVaccinesPageTitles.DepleteBatch);

    useAnalytics([
        "service",
        SiteVaccinesAnalyticsPageNames.PrimaryCategory,
        AddBatchAnalyticsSubCategories.SubCategory1,
        DepleteBatchAnalyticsSubCategories.SubCategory2Deplete
    ]);

    let depletedDate = batch && batch?.DepletedDate?.includes('T') ? RemoveTime(batch?.DepletedDate) : batch && !batch?.DepletedDate?.includes('T') ? batch?.DepletedDate : ''

    const formik = useFormik({
        initialValues: {
            DepletedDate_1: batch ? depletedDate?.split('-')[2] : '',
            DepletedDate_2: batch ? depletedDate?.split('-')[1] : '',
            DepletedDate_3: batch ? depletedDate?.split('-')[0] : '',
        },

        enableReinitialize: true,

        onSubmit: (async (values) => {
            setBatchDepletedDateRequiredError(false);
            setBatchDepletedDateError(false);
            setFutureExpiryDateError(false);

            let batchData = values as unknown as BatchDto;

            batchData = batch ? batch : batchData

            if (!values.DepletedDate_1 && !values.DepletedDate_2 && !values.DepletedDate_3) {
                setBatchDepletedDateRequiredError(true);
                return;
            }

            if (values.DepletedDate_1 || values.DepletedDate_2 || values.DepletedDate_3) {
                let day = values.DepletedDate_1;
                let month = values.DepletedDate_2;
                let year = values.DepletedDate_3;

                if (/^(d{1,2})$/.test(day)) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if (/^(d{1,2})$/.test(month)) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if (/^(d{2,4})$/.test(year)) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if (!year) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if (year?.toString()?.length <= 3 || year?.toString()?.length > 4) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if (Number(month) < 1 || Number(month) > 12) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if (Number(day) < 1 || Number(day) > 31) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if ((Number(month) === 4 || Number(month) === 6 || Number(month) === 9 || Number(month) === 11) && Number(day) === 31) {
                    setBatchDepletedDateError(true);
                    return;
                }

                if (Number(month) === 2) {
                    const isLeapYear = ((Number(year) % 4 === 0 && Number(year) % 100 !== 0) || Number(year) % 400 === 0);

                    if ((Number(day) > 29 || (Number(day) === 29 && !isLeapYear))) {
                        setBatchDepletedDateError(true);
                        return;
                    }
                }

                const inputDate = new Date(year + '-' + month + '-' + day);
                const currentDate = new Date();
                const allowedDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
                if (inputDate <= allowedDate) {
                    setFutureExpiryDateError(true);
                    return;
                }

                if (values.DepletedDate_1 && values.DepletedDate_1 && values.DepletedDate_1) {
                    batchData.DepletedDate = values.DepletedDate_3 + '-' + values.DepletedDate_2 + '-' + values.DepletedDate_1;

                    setBatch(batchData);

                    //perform api update (deplete)
                    var returnedResult = await siteVaccinesService.depleteSiteVaccineBatch$(batchData);
                    setSiteVaccines(returnedResult.SitesVaccines)
                    let updatedOptions = options
                    updatedOptions.Sites = returnedResult.Sites;
                    setOptions(prevState => ({
                        ...prevState,
                        Sites: returnedResult.Sites,
                    }));
                    navigate(SiteVaccinesPaths.SiteVaccineBatchesList, { state: [updatedOptions, returnedResult.Sites, returnedResult.SitesVaccines, returnedResult.SitesVaccines.find(sv => sv.SiteVaccineId === siteVaccine.SiteVaccineId) as SiteVaccines, { ShowBatchAlert: false, Batch: batchData, DepleteAction: true }] });
               
                }
                else {
                    setBatchDepletedDateError(true);
                    return;
                }
            }
        })           
    });

    const onFieldChange = (e) => {
        setBatchDepletedDateRequiredError(false);
        setBatchDepletedDateError(false);
        setFutureExpiryDateError(false);
    }

    return (
        <>
            {location && location.state ?
                <>
                    <div className="nhsuk-back-link">
                        <Link className="nhsuk-back-link__link" to={{ pathname: SiteVaccinesPaths.SiteVaccineBatchesList }}
                            state={[options, userSites, siteVaccines, siteVaccine, null]}><svg className="nhsuk-icon nhsuk-icon__chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" height="24" width="24">
                                <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                            </svg> Back
                        </Link>
                    </div>

                    <div className="nhsuk-grid-row mt-3">
                        <div className="nhsuk-grid-column-full">

                            {(batchDepletedDateRequiredError || batchDepletedDateError || futureExpiryDateError) &&
                                <>
                                    <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert">
                                        <h2 className="nhsuk-error-summary__title" id="error-summary-title">
                                            There is a problem
                                        </h2>
                                        <div className="nhsuk-error-summary__body">
                                            <ul className="nhsuk-list nhsuk-error-summary__list">
                                            {batchDepletedDateRequiredError &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'DepletedDate_1') })}>Enter the depleted date</Link></li>
                                                }

                                            {batchDepletedDateError &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'DepletedDate_1') })}>Enter the depleted date in the correct format</Link></li>
                                                }

                                            {futureExpiryDateError &&
                                                <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'DepletedDate_3') })}>Depleted date must be in the future</Link></li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }

                            <form onSubmit={formik.handleSubmit}>
                                <fieldset className="nhsuk-fieldset mb-3">
                                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                                        <h1 className="nhsuk-fieldset__heading">
                                            Deplete batch
                                        </h1>
                                    </legend>

                                    <div className="nhsuk-hint" id="select-site-hint">
                                        This batch will be marked as <strong className="nhsuk-tag nhsuk-tag--grey"> Pending</strong> until midnight of your depletion date.
                                    </div>
                                </fieldset>

                                <div className={"nhsuk-form-group " + ((batchDepletedDateRequiredError || batchDepletedDateError || futureExpiryDateError) ? "nhsuk-form-group--error" : "")}>
                                    <fieldset className="nhsuk-fieldset" aria-describedby="DepletedDate-hint" role="group">
                                        <legend className="nhsuk-fieldset__legend">
                                            Depleted date
                                        </legend>
                                        <div className="nhsuk-hint" id="DepletedDate-hint">
                                            For example, 31 03 {new Date().getFullYear() + 1}
                                        </div>

                                        <div className="nhsuk-date-input" id="DepletedDate">
                                            {batchDepletedDateRequiredError &&
                                                <span key="DepletedDateErrorRequiredSpan" className="nhsuk-error-message" id="DepletedDateErrorRequiredId">
                                                    <span key="DepletedDateErrorRequiredSpanHidden" className="nhsuk-u-visually-hidden">Error:</span> Enter the depleted date
                                                </span>
                                            }

                                            {batchDepletedDateError &&
                                                <span key="DepletedDateErrorSpan" className="nhsuk-error-message" id="DepletedDateErrorId">
                                                    <span key="DepletedDateErrorSpanHidden" className="nhsuk-u-visually-hidden">Error:</span> Enter the depleted date in the correct format
                                                </span>
                                            }

                                            {futureExpiryDateError &&
                                                <span key="DepletedDateFutureErrorSpan" className="nhsuk-error-message" id="DepletedDateFutureErrorId">
                                                    <span key="DepletedDateFutureErrorSpanHidden" className="nhsuk-u-visually-hidden">Error:</span> Depleted date must be in the future
                                                </span>
                                            }

                                            <div className="nhsuk-date-input__item">
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="DepletedDate_1">
                                                        Day
                                                    </label>

                                                    <input className={"nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " + ((batchDepletedDateRequiredError || batchDepletedDateError || futureExpiryDateError) ? "nhsuk-input--error" : "")}
                                                        id="DepletedDate_1" name="DepletedDate_1" type="number" inputMode="numeric"
                                                        value={formik.values.DepletedDate_1}
                                                        placeholder="DD"
                                                        maxLength={2}
                                                        onChange={(e) => { onFieldChange(e); formik.handleChange(e); }}
                                                        onBlur={(e) => { onFieldChange(e); formik.handleChange(e); }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="nhsuk-date-input__item">
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="DepletedDate_2">
                                                        Month
                                                    </label>

                                                    <input className={"nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " + ((batchDepletedDateRequiredError || batchDepletedDateError || futureExpiryDateError) ? "nhsuk-input--error" : "")}
                                                        id="DepletedDate_2" name="DepletedDate_2" type="number" inputMode="numeric"
                                                        value={formik.values.DepletedDate_2}
                                                        placeholder="MM"
                                                        maxLength={2}
                                                        onChange={(e) => { onFieldChange(e); formik.handleChange(e); }}
                                                        onBlur={(e) => { onFieldChange(e); formik.handleChange(e); }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="nhsuk-date-input__item">
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="DepletedDate_3">
                                                        Year
                                                    </label>

                                                    <input className={"nhsuk-input nhsuk-date-input__input nhsuk-input--width-4 " + ((batchDepletedDateRequiredError || batchDepletedDateError || futureExpiryDateError) ? "nhsuk-input--error" : "")}
                                                        id="DepletedDate_3" name="DepletedDate_3" type="number" inputMode="numeric"
                                                        value={formik.values.DepletedDate_3}
                                                        placeholder="YYYY"
                                                        maxLength={4}
                                                        onChange={(e) => { onFieldChange(e); formik.handleChange(e); }}
                                                        onBlur={(e) => { onFieldChange(e); formik.handleBlur(e); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>

                                <div className="mt-3">
                                    <Button type="submit" className="nhsuk-button">Confirm</Button>
                                </div>

                            </form>
                        </div>
                    </div>

                </>
                :
                redirect()
            }
        </>
    )
}