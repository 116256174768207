import React from 'react'
import { FormatNhsNumber, IsoToUkDate } from '../../_shared/shared.functions'
import { Link, useNavigate } from 'react-router-dom';
import NhsActionLink from '../../_shared/components/NHSUK/NhsActionLink';
import { Button } from 'reactstrap';
import { PatientPaths } from './patient.enums';

export default function PatientList({ patient, patientSearchType, searchValues }) {
    const navigate = useNavigate();

    const recordVaccination = () => {
        navigate(`/patient/${patient.PatientId}`);
    }

    const goToAddPatient = () => {
        navigate(PatientPaths.AddPatient, { state: [null] });
    }

    const createNewPatientButton = <Button type='button'
        className='nhsuk-button' onClick={goToAddPatient}
        data-module="nhsuk-button">Create new patient
    </Button>

    return (
        <>
            {patient && !patient.TooManyReturnedResults ?
                <>
                    <table role="table" className="nhsuk-table-responsive">
                        <thead className="nhsuk-table__head">
                            <tr role="row">
                                <th role="columnheader" scope="col">Personal details</th>
                                <th role="columnheader" scope="col">NHS number</th>
                                <th role="columnheader" scope="col">Date of birth</th>
                                <th role="columnheader" scope="col">Address</th>
                            </tr>
                        </thead>
                        <tbody className="nhsuk-table__body">
                            <tr key={patient.PatientId} role="row" className="nhsuk-table__row">
                                <td className="nhsuk-table__cell" role="cell">
                                    <><span className="nhsuk-table-responsive__heading">Personal details</span>
                                        <NhsActionLink text={patient.FirstName + ' ' + patient.LastName} onClick={recordVaccination}></NhsActionLink>
                                    </>
                                </td>
                                <td className="nhsuk-table__cell" role="cell">
                                    <><span className="nhsuk-table-responsive__heading">NHS number</span>
                                        {patient.NhsNumber}
                                    </>
                                </td>
                                <td className="nhsuk-table__cell" role="cell">
                                    <><span className="nhsuk-table-responsive__heading">Date of birth</span>
                                        {IsoToUkDate(patient.DateOfBirth)}
                                    </>
                                </td>
                                <td className="nhsuk-table__cell" role="cell"><><span className="nhsuk-table-responsive__heading">Address</span>
                                    {patient.Address && patient.Address + ','} {patient.Postcode}
                                </>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>
                :
                <>
                    {patient && patient.TooManyReturnedResults === true ?
                        <>
                            <h3 key="TooManyReturnedResults" className="mb-4 mt-5">More than one result found, please refind your search. Visit our <Link className='nhsuk-card__link' to="/search-tips">search tips</Link></h3>
                        </>
                        : ''
                    }
                </>
            }

            <>
                {!patient && searchValues && patientSearchType && patientSearchType.NhsNumberSearch ?
                    <>
                        <h3 key="NhsNumberSearchNoResultFound" className="mb-4 mt-5">No result found for <b>{FormatNhsNumber(searchValues.toString())}</b></h3>
                        <label className="nhsuk-label fs-5 mb-3">
                            <b>What next?</b>
                        </label>
                        <div className="fs-5">Search for a patient by their demographics or in your local records.</div>
                        <br></br>
                        {createNewPatientButton}
                    </>
                    :
                    <>
                        {!patient && searchValues && patientSearchType && patientSearchType.PdsSearch ?
                            <>
                                <h3 key="PdsSearchNoResultFound" className="mb-4">No result found for "<b>{searchValues.FirstName}, {searchValues.LastName}, {searchValues.Gender ? <>{searchValues.Gender},</> : ''} {searchValues.Postcode ? <>{searchValues.Postcode},</> : ''} {IsoToUkDate(searchValues.DateOfBirth)}</b>"</h3>
                                <label className="nhsuk-label fs-5">
                                    <b>What next?</b>
                                </label>
                                <ul className="fs-5">
                                    <li>Check frequently misspelt fields like the patient's name and postcode.</li>
                                    <li>Ask the patient to spell their name. For example, Catherine could be Katherine, or Smith could be Smythe.</li>
                                </ul>
                                <div><Link className='nhsuk-card__link fs-5' to="/search-tips">Learn more</Link></div>
                                <br></br>
                                <div className="fs-5">Or create a new patient, if you're confident there is no existing patient record.</div>
                                <br></br>
                                {createNewPatientButton}
                            </>
                            : ''
                        }
                    </>
                }
            </>
        </>
    )
}