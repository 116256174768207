import { RoleIds } from "../../components/user/user.enums";
import { useUser } from "../../components/user/UserProvider";


const useRegionalAdministratorCheck = () => {
  const user = useUser();

  const isRegionalAdministrator = user?.Roles?.includes(RoleIds.RegionalAdministrator);
  
  const shouldNavigateToRegional = user?.IsRegionLead && isRegionalAdministrator;

  return {
    user,
    shouldNavigateToRegional
  };
};

export default useRegionalAdministratorCheck;
