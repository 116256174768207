import React, { useEffect, useState } from "react";
import './email-preview-layout.scss';
import { NotifyTemplateType } from "../../../_shared/shared.enums";
import useNotifyService from "../../../_shared/services/notify/notify.service";
import { Loading } from "../../../_shared/components/Loading";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface EmailPreviewProps {
  email: string;
  templateType: NotifyTemplateType;
  personalisation?: { [key: string]: any }
}

const EmailPreview: React.FC<EmailPreviewProps> = ({ email, templateType, personalisation }) => {
  const [template, setTemplate] = useState<string | null>(null);

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      try {
        const template = await useNotifyService.getEmailTemplate$(templateType, personalisation);
        setTemplate(template);
      } catch (error) {
        console.error('Error fetching email template:', error);
        setTemplate("Unable to fetch email template");
      }
    };

    fetchEmailTemplate();

  }, [email, templateType, personalisation]);

  if (template === null) {
    return <Loading message={"Loading email preview"} />;
  }
  
  return (
    <div className="app-email-preview">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {template}
      </ReactMarkdown>
    </div>
  );
};

export default EmailPreview;
