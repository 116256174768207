import { appAxios } from '../../../_shared/axios';
import { NotifyTemplateType } from '../../shared.enums';

export class NotifyService {
  private basePath = '/api/notify';

  async getEmailTemplate$(templateType: NotifyTemplateType, personalisation?: { [key: string]: any }): Promise<string> {
    let url = `${this.basePath}/gettemplate/${templateType}`;

    if (personalisation) {
      const personalisationStr = encodeURIComponent(JSON.stringify(personalisation));
      url += `?personalisation=${personalisationStr}`;
    }
    return (await appAxios.get(url)).data;
  }

  static get instance() { return useNotifyService }
}

const useNotifyService = new NotifyService();

export default useNotifyService