import { Table } from 'nhsuk-react-components';
import React from 'react';
import './NhsTable.scss';

interface NhsTableProps<T> {
  headers: { displayName: string, key: string }[];
  data: T[];
  tableCaption?: string;
  onRowClick?: (id: T) => void;
  targetColumn?: string;
}

const NhsTable = <T,>({ headers, data, tableCaption, onRowClick, targetColumn }: NhsTableProps<T>) => {

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <>

      <Table aria-label={tableCaption}>
        <caption className="nhsuk-table__caption">{tableCaption}</caption>
        <Table.Head>
          <Table.Row>
            {headers.map((header, index) => (
              <Table.Cell key={index} role="columnheader" scope="col">
                {header.displayName}
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((row, rowIndex) => (
            <Table.Row
              key={rowIndex}
              onClick={() => !targetColumn && handleRowClick(row)}
              className={!targetColumn ? 'nhs-table-row-clickable' : ''}
              tabIndex={rowIndex}
              role="row"
            >
              {headers.map((header, colIndex) => (
                <Table.Cell
                  key={colIndex}
                  onClick={(e) => {
                    if (header.key === targetColumn) {
                      e.stopPropagation();
                      handleRowClick(row);
                    }
                  }}
                  className={`${header.key === targetColumn ? 'nhs-table-cell-clickable' : ''} nhs-table-width`}
                  role="cell"
                >
                  {row[header.key]}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default NhsTable;
