import * as Yup from 'yup';
import userManagementService from "./services/user-management/userManagement.service";

export function ValidPostcode(value) {
  return /^^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1}( )[0-9]{1}[A-Z]{2}$/.test(value);
}

export function ValidPhoneNumber(value) {
  return /^^(0)[0-9]{10}$/.test(value);
}

//#region NHS number
export function ValidNhsNumber(value) {
  return !isInvalid(value);
}

function isInvalid(val: any): boolean {
  if (!/^^[0-9]{10}$/.test(val)) return true;

  const valStr = val + '';
  const valArr = valStr.split('');
  const factorArr = [10, 9, 8, 7, 6, 5, 4, 3, 2];
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    const s = +valArr[i] * factorArr[i];
    sum += s;
  }

  const checkDigit = getCheckDigit(sum);
  if (checkDigit === '10') return true;

  // tslint:disable-next-line: triple-equals
  return valArr[9] != checkDigit;
}

function getCheckDigit(sum: number): string {
  const reminder = sum % 11;
  const checkDigit = 11 - reminder;
  if (checkDigit === 11) {
    return '0';
  }
  return checkDigit.toString();
}
//#endregion

//#region Email Validation
export const emailValidationSchema = (
  isEditing: boolean,
  emailDomainCache: { [email: string]: boolean },
  emailDuplicateCache: { [email: string]: boolean }
) => {
  return Yup.string()
    .email('Enter an email address in the correct format, like name@example.com')
    .required('Enter email address')
    .max(100, 'Email must be 100 characters or less')
    .test('is-approved-domain', 'Enter an NHS email address', async (value: string) => {
      if (isEditing) return true;
      if (!value.trim()) return false;

      const cachedResult = emailDomainCache[value];
      if (cachedResult !== undefined) {
        return cachedResult; // Return the cached result true means domain is valid
      }

      try {
        if (value.endsWith('nhs.uk')) {
          return true;
        }

        const isValid = await userManagementService.checkValidDomain$(value);
        emailDomainCache[value] = isValid;
        return isValid; // true means domain is valid
      } catch (error) {
        return false;
      }

    })
    .test('is-duplicate-email', 'Email already exists', async (value: string) => {
      if (isEditing) return true;
      if (!value.trim()) return false;

      const cachedResult = emailDuplicateCache[value];
      if (cachedResult !== undefined) {
        return !cachedResult; // Return the cached result true means duplicate email exists
      }

      try {
        const isDuplicate = await userManagementService.checkEmailExists$(value);
        emailDuplicateCache[value] = isDuplicate;
        return !isDuplicate; // true means there is a duplicate which is invalid
      } catch (error) {
        return false; // If it can't be checked then show error msg
      }
    });
};