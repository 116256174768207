import React from 'react'
import './cookie.scss'
import { Link } from 'react-router-dom';
import { RadioValues } from '../../../_shared/shared.enums';
import { useCookie } from './CookieProvider';

export default function CookieBanner() {
  const { cookieConsent, updateCookie} = useCookie();

  return (
    <>
      { !cookieConsent &&
        <div id="nhsuk-cookie-banner" data-nosnippet>
          <div className="nhsuk-cookie-banner" id="cookiebanner">
            <div className="nhsuk-width-container"> 
              <h2>Cookies on the Record a vaccination website</h2> 
              <p>We've put some small files called cookies on your device to make our site work.</p>
              <p>We'd also like to use analytics cookies. These send anonymous information about how our site is used to a service called Adobe Analytics. We use this information to improve our site.</p>
              <p>Let us know if this is OK. We'll use a cookie to save your choice. You can <Link to="/cookie/policy">read more about our cookies</Link> before you choose.</p>
              <ul> 
                <li><button className='nhsuk-button' id="nhsuk-cookie-banner__link_accept_analytics" onClick={() => updateCookie(RadioValues.true)}>I'm OK with analytics cookies</button></li>
                <li><button className='nhsuk-button' id="nhsuk-cookie-banner__link_accept" onClick={() => updateCookie(RadioValues.false)}>Do not use analytics cookies</button></li>
              </ul>
            </div>
          </div>
        </div> 
      }  
    </> 
  )
}
