import * as React from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IsoToUkDate, Redirect } from '../../_shared/shared.functions';
import { Paths } from '../../_shared/shared.enums';
import { Button } from 'reactstrap';
import { SiteVaccines, AddEditSiteVaccineBatchDto } from './site-vaccines.models';
import { AddSiteVaccineBatchAnalyticsSubCategories, SiteVaccinesAnalyticsPageNames, SiteVaccinesPageTitles, SiteVaccinesPaths } from './site-vaccines.enums';
import siteVaccinesService from './site-vaccines.service';
import { BatchDto, ExistingSiteVaccineBatch } from '../batch/batch.models';
import useAnalytics from '../analytics/hooks/useAnalytics';
import useDocumentTitle from '../../_shared/hooks/useDocumentTitle';

export default function ConfirmSiteVaccineBatch() {
    useAnalytics([
        "service",
        SiteVaccinesAnalyticsPageNames.PrimaryCategory,
        AddSiteVaccineBatchAnalyticsSubCategories.SubCategory1,
        AddSiteVaccineBatchAnalyticsSubCategories.SubCategory2
    ]);
    useDocumentTitle(SiteVaccinesPageTitles.ConfirmSiteVaccineBatch);

    const location = useLocation();
    const navigate = useNavigate();

    const redirect = () => {
        Redirect(Paths.Home);
    }

    const optionsStateData = location && location.state ? location.state[0] as any : redirect();
    const userSitesStateData = location && location.state ? location.state[1] as any : redirect();
    const siteVaccinesStateData = location && location.state ? location.state[2] as SiteVaccines[] : redirect();
    const batchStateData = location && location.state ? location.state[3] as BatchDto : redirect();
    const siteVaccineBatchesStateData = location && location.state ? location.state[4] as BatchDto[] : redirect();
    const addOrEditStateData = location && location.state ? location.state[5] as any : redirect();
    const depleteOrUndepleteStateData = location && location.state ? location.state[6] as any : redirect();
    const siteVaccineStateData = location && location.state ? location.state[7] as SiteVaccines : redirect();


    const [options, setOptions] = React.useState(optionsStateData as any);
    const [userSites] = React.useState(userSitesStateData);
    const [siteVaccines, setSiteVaccines] = React.useState(siteVaccinesStateData as SiteVaccines[]);
    const [batch, setBatch] = React.useState(batchStateData as BatchDto);
    const [siteVaccineBatches, setSiteVaccineBatches] = React.useState(siteVaccineBatchesStateData as BatchDto[]);
    const [addOrEdit] = React.useState(addOrEditStateData as any);
    const [depleteOrUndeplete] = React.useState(depleteOrUndepleteStateData as any);
    const [siteVaccine] = React.useState(siteVaccineStateData as SiteVaccines);

    const [existingSiteVaccineBatch, setExistingSiteVaccineBatch] = React.useState(null as ExistingSiteVaccineBatch);

    const submitData = async () => {
        let data: AddEditSiteVaccineBatchDto = {
            Batch: batch
        }

        var returnedResult = await siteVaccinesService.submitSiteVaccineBatch$(data);

        if (returnedResult?.SitesVaccines[0]?.ExistingSiteVaccineBatch) {
            setExistingSiteVaccineBatch(returnedResult.SitesVaccines[0].ExistingSiteVaccineBatch);
        }
        else {
            setSiteVaccines(returnedResult.SitesVaccines)
            let updatedOptions = options
            updatedOptions.Sites = returnedResult.Sites;
            setOptions(prevState => ({
                ...prevState,
                Sites: returnedResult.Sites,
            }));
            navigate(SiteVaccinesPaths.SiteVaccinesList, {
                state: [updatedOptions, returnedResult.SitesVaccines]
            });
        }
    }

    const errorMessageDescription = "Error! Vaccine batch already exist at this site.";

    return (
        <>
            {location && location.state ?
                <>
                    <Link className="nhsuk-back-link__link" to={{ pathname: SiteVaccinesPaths.AddBatch }}
                        state={[options, userSites, siteVaccines, batch, siteVaccineBatches, addOrEdit, null, siteVaccine]}><svg className="nhsuk-icon nhsuk-icon__chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" height="24" width="24">
                            <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                        </svg> Back
                    </Link>

                    <div className='row flex-column-reverse flex-md-row mb-2'>
                        <div className='col-md-8'>
                            <div className="nhsuk-grid-row mt-3">
                                <div className="nhsuk-grid-column-full">
                                    <fieldset className="nhsuk-fieldset mb-3">
                                        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                                            <h1 className="nhsuk-fieldset__heading">
                                                Check and confirm
                                            </h1>
                                        </legend>

                                        {existingSiteVaccineBatch ?
                                            <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                                                <h2 className="nhsuk-error-summary__title" id="error-summary-title">
                                                    There is a problem
                                                </h2>
                                                <div className="nhsuk-error-summary__body">
                                                    <p>
                                                        {errorMessageDescription}
                                                    </p>
                                                    <ul className="nhsuk-list nhsuk-error-summary__list" role="list">
                                                        <li>{existingSiteVaccineBatch.BatchNumber} for {existingSiteVaccineBatch.Vaccine} exists at {existingSiteVaccineBatch.Site}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            : <div></div>
                                        }

                                        <dl className="nhsuk-summary-list">
                                            <div className="nhsuk-summary-list__row">
                                                <dt className="nhsuk-summary-list__key">
                                                    <label className="nhsuk-label"><b>Site name</b></label>
                                                </dt>

                                                <dd className="nhsuk-summary-list__value">
                                                    {batch?.Site}
                                                </dd>
                                            </div>

                                            <div className="nhsuk-summary-list__row">
                                                <dt className="nhsuk-summary-list__key">
                                                    <label className="nhsuk-label"><b>ODS code</b></label>
                                                </dt>

                                                <dd className="nhsuk-summary-list__value">
                                                    {batch?.Code}
                                                </dd>
                                            </div>

                                            <div className="nhsuk-summary-list__row">
                                                <dt className="nhsuk-summary-list__key">
                                                    <label className="nhsuk-label"><b>Address</b></label>
                                                </dt>

                                                <dd className="nhsuk-summary-list__value">
                                                    {batch?.AddrLn1}, {batch?.AddrLn2 ? <>{batch?.AddrLn2},</> : ''} {batch?.Town}, {batch?.County ? <>{batch?.County},</> : ''} <br></br>{batch?.PostCode}
                                                </dd>
                                            </div>
                                            <br></br>
                                            { batch.IsParent && !addOrEdit?.IsAddEditBatchFromSiteVaccinesList && !addOrEdit?.IsAddEditBatchFromSiteBatchesList &&
                                                <Link to={{ pathname: SiteVaccinesPaths.AddSite }} state={[options, userSites, siteVaccines, batch]}> Change site</Link>
                                            }
                                            <br></br>
                                            <br></br>

                                            <div className="nhsuk-summary-list__row">
                                                <dt className="nhsuk-summary-list__key">
                                                    <label className="nhsuk-label"><b>Vaccine</b></label>
                                                </dt>

                                                <dd className="nhsuk-summary-list__value">
                                                    {batch?.VaccineProgram}
                                                </dd>
                                            </div>

                                            <div className="nhsuk-summary-list__row">
                                                <dt className="nhsuk-summary-list__key">
                                                    <label className="nhsuk-label"><b>Product Name</b></label>
                                                </dt>

                                                <dd className="nhsuk-summary-list__value">
                                                    {batch?.Vaccine}
                                                </dd>
                                            </div>
                                            <br></br>
                                            {!addOrEdit?.IsAddEditBatchFromSiteVaccinesList && !addOrEdit?.IsAddEditBatchFromSiteBatchesList &&
                                                <Link to={{ pathname: SiteVaccinesPaths.AddVaccine }} state={[options, userSites, siteVaccines, batch]}> Change vaccine</Link>
                                            }
                                            <br></br>
                                            <br></br>

                                            <div className="nhsuk-summary-list__row">
                                                <dt className="nhsuk-summary-list__key">
                                                    <label className="nhsuk-label"><b>Batch number</b></label>
                                                </dt>

                                                <dd className="nhsuk-summary-list__value">
                                                    {batch?.BatchNumber}
                                                </dd>
                                            </div>

                                            <div className="nhsuk-summary-list__row">
                                                <dt className="nhsuk-summary-list__key">
                                                    <label className="nhsuk-label"><b>Expiry date</b></label>
                                                </dt>

                                                <dd className="nhsuk-summary-list__value">
                                                    {IsoToUkDate(batch?.ExpiryDate)}
                                                </dd>
                                            </div>
                                            <br></br>
                                            {!addOrEdit?.IsAddEditBatchFromSiteVaccinesList && !addOrEdit?.IsAddEditBatchFromSiteBatchesList ?
                                                <Link to={{ pathname: SiteVaccinesPaths.AddBatch }} state={[options, userSites, siteVaccines, batch]}> Edit batch</Link>
                                                :
                                                <>
                                                    {addOrEdit?.IsAddEditBatchFromSiteVaccinesList ?
                                                        <Link to={{ pathname: SiteVaccinesPaths.AddBatch }} state={[options, userSites, siteVaccines, batch, siteVaccine?.SiteVaccineBatches, { IsAddBatch: true, IsEditBatch: false, IsAddEditBatchFromSiteVaccinesList: true, IsAddEditBatchFromSiteBatchesList: false }, null, siteVaccine]}> Edit batch</Link>
                                                        :
                                                        <>
                                                            {addOrEdit?.IsAddEditBatchFromSiteBatchesList &&
                                                                <Link to={{ pathname: SiteVaccinesPaths.AddBatch }} state={[options, userSites, siteVaccines, batch, siteVaccine?.SiteVaccineBatches, { IsAddBatch: true, IsEditBatch: false, IsAddEditBatchFromSiteVaccinesList: false, IsAddEditBatchFromSiteBatchesList: true }, null, siteVaccine]}> Edit batch</Link>
                                                            }
                                                        </>
                                                    }
                                                </>

                                            }
                                        </dl>

                                        <div className="mt-3">
                                            <Button type="button" onClick={submitData} className='nhsuk-button'>
                                                Confirm
                                            </Button>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : redirect()
            }
        </>
    )
}
