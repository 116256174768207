import { ProfileUserName, User } from './user.models';
import { SessionStorageKeys } from '../../_shared/shared.enums';
import { appAxios } from '../../_shared/axios';
import { SetPolicies } from './user.functions';
import vaccinationService from '../vaccination/vaccination.service';

export class UserService {
  private basePath = 'api/user/';
  _callbacks = [];
  _nextSubscriptionId = 0;
  _apiCallInProgress = false;

  async getUser$() {
    const user = this.getUser();
    if (user) {
      return user;
    }

    if (!this._apiCallInProgress) {
      this._apiCallInProgress = true;

      await appAxios.get(`${this.basePath}get`)
        .then(response => {
          this.setUser(response?.data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this._apiCallInProgress = false;
        });
    }
    return this.getUser();
  }

  getUser(): User {
    let user = null;
    try {
      user = JSON.parse(sessionStorage.getItem(SessionStorageKeys.User));
    } catch (error) {
      return null;
    }

    if (user && new Date() < new Date(user.RefreshDateTime)) {
      return user;
    }
    return null;
  }

  setUser(user: User) {
    if (user) {
      const date = new Date();
      date.setMinutes(date.getMinutes() + user.SessionMinutes);
      user.RefreshDateTime = date;
      SetPolicies(user);
    }

    sessionStorage.setItem(SessionStorageKeys.User, JSON.stringify(user));
    this.notifySubscribers();
  }

  refreshUserSessionName(updatedUser: ProfileUserName){
    const user = this.getUser();

    if(user != null){
      user.FirstName = updatedUser.FirstName;
      user.LastName = updatedUser.LastName;
      sessionStorage.setItem(SessionStorageKeys.User, JSON.stringify(user));
      this.notifySubscribers();
    }
  }

  subscribe(callback) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
      .filter(element => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  private notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  async getLeadAdmins$(): Promise<User[]> {
    try {
      return await appAxios.get(`${this.basePath}GetLeadAdmins`);
    } catch (error) {
      console.error("Failed to fetch lead admins");
      return [];
    }
  }

  async updateUserName$(model: ProfileUserName) : Promise<Number> {
    try {
      vaccinationService.forceRefreshOptions()
      return (await appAxios.post(`${this.basePath}UpdateUserName`, model)).data;
    } catch (error) {
      console.error("Failed to update name");
      return null;
    }
    
  }

  static get instance() { return userService }
}

const userService = new UserService();

export default userService;
