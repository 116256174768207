import React from 'react'
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

export default function Footer() {
  return (
    <footer role="contentinfo">
      <div className="nhsuk-footer-container">
        <div className="nhsuk-width-container">
          <h2 className="nhsuk-u-visually-hidden">Support links</h2>
          <div className="nhsuk-footer">
            <ul className="nhsuk-footer__list">
              <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item">
                  <NavLink  className="nhsuk-footer__list-item-link" tag={Link} to="/report-an-issue">Report an issue</NavLink>
              </li>
              <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item">
                <NavLink  className="nhsuk-footer__list-item-link" tag={Link} to="/help-and-guidance">Help and guidance</NavLink>
              </li>
              <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item">
                <NavLink  className="nhsuk-footer__list-item-link" tag={Link} to="/contact-us">Contact us</NavLink>
              </li>
              <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item">
                <NavLink  className="nhsuk-footer__list-item-link" tag={Link} to="/cookie/policy">Cookie policy</NavLink>
              </li>
            </ul>
            <div>
              <p className="nhsuk-footer__copyright">&copy; NHS England</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
