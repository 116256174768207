import React, { useState, useEffect, useMemo } from 'react';
import {  useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import vaccinationService from './vaccination.service';
import { AssessmentOutcomeIds, CareModelIds, ClinicianRoleIds, ConsentTypeIds, EligibilityTypeIds, LegalMechanismIds, VaccinationSteps, VaccinationTitles, VaccineProgramIds } from './vaccination.enums';
import { object, string, date } from 'yup';
import VaccinationProgress from './VaccinationProgress';
import PatientDetails from '../patient/PatientDetails';
import { RadioValues, PageType } from '../../_shared/shared.enums';
import NhsOptionRadio from '../../_shared/components/form/NhsOptionRadio';
import NhsYesNo from '../../_shared/components/form/NhsYesNo';
import NhsSelect from '../../_shared/components/form/NhsSelect';
import NhsInput from '../../_shared/components/form/NhsInput';
import NhsDateSummary from '../../_shared/components/summary/NhsDateSummary';
import NhsOptionSummary from '../../_shared/components/summary/NhsOptionSummary';
import NhsSummary from '../../_shared/components/summary/NhsSummary';
import NhsYesNoSummary from '../../_shared/components/summary/NhsYesNoSummary';
import { GetAge, IsoToUkDate, RemoveTime, RemoveValues, DateToLongFormat, toTitleCase, ScrollToTop } from '../../_shared/shared.functions';
import { Vaccination } from './vaccination.models';
import NhsErrorSummary from '../../_shared/components/NHSUK/NhsErrorSummary';
import NhsBackLink from '../../_shared/components/NHSUK/NhsBackLink';
import { useFormik } from 'formik';
import { HasErrors, SetTouched, IncorrectInterval } from './vaccination.functions';
import NhsTextArea from '../../_shared/components/form/NhsTextArea';
import batchService from '../batch/batch.service';
import vaccinatorLocationService from '../vaccinator-location/vaccinator-location.service';
import usePageDetails from './hooks/usePageDetails';
import useCachedPatient from './hooks/useCachedPatient';
import ConsentVaccineRadios from './ConsentVaccineRadios';
import NhsDateInput from '../../_shared/components/form/NhsDateInput';
import useVaccinationTitle from './hooks/useVaccinationTitle';
import './Vaccination.scss';
import { useUser } from '../user/UserProvider';
import NhsAutocompleteInput from '../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteInput';
import NhsAutocompleteSuggestions from '../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteSuggestions';
import { ValidPostcode } from '../../_shared/shared.validation';
import moment from 'moment';
import { NhsdOrganisation } from '../../_shared/shared.models';
import { Loading } from '../../_shared/components/Loading';


const todaysDate = new Date();
const currentYear = todaysDate.getFullYear();
const currentMonth = todaysDate.getMonth();
const minDate = new Date(currentYear - 1, currentMonth, todaysDate.getDate());
if (isLeapYear(currentYear - 1) && currentMonth === 1) {
  minDate.setDate(28);
}
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}


const formSettings = [
  { //Vaccine
    OrganisationId: {
      Validation: string().required()
    },
    SiteId: {
      Label: 'Delivery team',
      get Validation() { return string().required(`Select delivery team`) }
    },

    PatientId: {
      Validation: string().required()
    },
    VaccineProgramId: {
      Label: 'Vaccine',
      get Validation() { return string().required(`${this.Label} is required`) }
    },
    ConsentVaccineId: {
      Label: 'Vaccine product',
      get Validation() {
        return string().required(this.Label === 'Vaccine product' ? 'Select a vaccine' : `${this.Label} is required`);
      }
    }
  },
  { //Assessment
    Eligible: {
      Label: 'Is the patient eligible for the vaccine?',
      get Validation() {
        return string().required(`Select 'Yes' if the patient is eligible, or 'No' if they are not`);
      }
    },
    EligibilityTypeId: {
      Label: 'Eligibility type',
      get Validation() {
        return string().when('Eligible', {
          is: RadioValues.true,
          then: schema => schema.required(`Select eligibility type`)
        })
      }
    },
    StaffRoleId: {
      Label: 'Staff role',
      get Validation() {
        return string().when('EligibilityTypeId', {
          is: EligibilityTypeIds.HealthCareWorker,
          then: schema => schema.required(`Select a staff role`)
        })
      }
    },
    ExpectedDueDate: {
      Label: 'Expected due date',
      get Validation() {
        return date().when(['EligibilityTypeId', 'VaccineProgramId'], {
          is: (e, v) => e === EligibilityTypeIds.Pregnancy && (v === VaccineProgramIds.RSV || v === VaccineProgramIds.Pertussis),
          then: schema => schema
            .test('required-if-touched', `${this.Label} is required`, function (value) {
              const isPregnancy = this.parent.EligibilityTypeId === EligibilityTypeIds.Pregnancy &&
                (this.parent.VaccineProgramId === VaccineProgramIds.RSV || this.parent.VaccineProgramId === VaccineProgramIds.Pertussis);
              if (isPregnancy) {
                return value instanceof Date ? !isNaN(value.getTime()) : false;
              }
              return true;
            })
            .typeError(`${this.Label} is invalid`)
        });
      }
    },

    AssessmentClinicianId: {
      Label: 'Assessing clinician',
      get Validation() {
        return string().required(`Select the ${this.Label.toLowerCase()}`);
      }
    },
    LegalMechanismId: {
      Label: 'Legal mechanism',
      get Validation() {
        return string().required(`Select a legal mechanism`);
      }
    },
    AssessmentDate: {
      Label: 'Assessment date',
      get Validation() {
        return date()
          .max(todaysDate, "Assessment date must be in the present or past")
          .min(minDate, "Date cannot be older than a year")
          .required(`Select an assessment date`);
      }
    },
    AssessmentOutcomeId: {
      Label: 'Assessment outcome',
      get Validation() {
        return string().required(`Select an ${this.Label.toLowerCase()}`);
      }
    },
    AssessmentNoVaccinationReasonId: {
      Label: 'Reason vaccine not given',
      get Validation() {
        return string().when('AssessmentOutcomeId', {
          is: AssessmentOutcomeIds.VaccineNotGiven,
          then: schema => schema.required(`Select a reason why the vaccine was not given`)
        });
      }
    },
    AssessmentComments: {
      Label: 'Comments (Optional)',
      get Validation() { return string().notRequired().max(500, `${this.Label} must be at most 500 characters`) }
    }
  },
  { //Consent
    Consented: {
      Label: 'Does the patient or someone on their behalf consent to the vaccination?',
      get Validation() { return string().required(`${this.Label} is required`) } // Haven't changed as impossible to continue without selecting.
    },
    NoConsentReasonId: {
      Label: 'No consent reason',
      get Validation() {
        return string().when('Consented', {
          is: RadioValues.false,
          then: schema => schema.required(`Select a no consent reason`)
        })
      }
    },
    ConsentTypeId: {
      Label: 'Consent given by',
      get Validation() {
        return string().when('Consented', {
          is: RadioValues.true,
          then: schema => schema.required(
            this.Label === 'Consent given by' ? 'Select the person giving consent' : `${this.Label} is required`
          )
        })
      }
    },
    NameOfPersonConsenting: {
      Label: 'Name of the person consenting',
      get Validation() {
        return string().when('ConsentTypeId', {
          is: v => (v && v !== '1'),
          then: s => s.required(`Enter the ${this.Label.toLowerCase()}`)
        });
      }
    },

    RelationshipToPatient: {
      Label: 'Relationship to the patient',
      get Validation() {
        return string().when('ConsentTypeId', {
          is: v => (v && v != '1'),
          then: s => s.required(`${this.Label} is required`)
        })
      }
    },
    ConsentClinicianId: {
      Label: 'Consenting clinician',
      get Validation() {
        return string().required(`Select the ${this.Label.toLowerCase()}`);
      }
    },
  },
  { //Vaccinate
    Vaccinated: {
      Label: 'Have you vaccinated the patient?',
      get Validation() {
        return string().required(`Select 'Yes' if you have vaccinated the patient, or 'No' if you haven't`);
      }
    },
    VaccinationDate: {
      Label: 'Vaccination date',
      get Validation() {
        return date()
          .max(todaysDate, "Vaccination date must be in the present or past")
          .min(minDate, "Date cannot be older than a year")
          .required(`Select a ${this.Label.toLowerCase()}`);
      }
    },
    NoVaccinationReasonId: {
      Label: 'No vaccination reason',
      get Validation() {
        return string().when('Vaccinated', {
          is: RadioValues.false,
          then: schema => schema.required(`Select a no vaccination reason`)
        });
      }
    },
    VaccineId: {
      Label: 'Vaccine',
      get Validation() {
        return string().when('Vaccinated', {
          is: RadioValues.true,
          then: schema => schema.required(`${this.Label} is required`)
        })
      }
    },
    VaccinationSiteId: {
      Label: 'Vaccination site',
      get Validation() {
        return string().when('Vaccinated', {
          is: RadioValues.true,
          then: schema => schema.required(`Select a vaccination site`)
        })
      }
    },
    BatchNumber: {
      Label: 'Batch number',
      get Validation() {
        return string().when(['Vaccinated'], {
          is: (vaccinated) => vaccinated === RadioValues.true,
          then: schema => schema.required(`Select a ${this.Label.toLowerCase()}`)
        });
      }
    },

    BatchExpiryDate: {
      Label: 'Batch expiry date',
      get Validation() {
        return string().when('Vaccinated', {
          is: RadioValues.true,
          then: schema => schema.required(`Enter a batch expiry date`)
        })
      }
    },
    DoseAmount: {
      Label: 'Dose amount (ml)',
      get Validation() {
        return string()
          .matches(/^(0)(.)[1-9]{1}$/, `${this.Label} is incorrect`)
          .when('Vaccinated', {
            is: RadioValues.true,
            then: schema => schema.required(`${this.Label} is required`)
          })
      }
    },
    VaccinatingClinicianId: {
      Label: 'Vaccinator',
      get Validation() {
        return string().required('Select the ' + this.Label?.toLowerCase());
      }
    },
    VaccinationComments: {
      Label: 'Comments (Optional)',
      get Validation() { return string().notRequired().max(500, `${this.Label} must be at most 500 characters`) }
    },
    CareModelId: {
      get Validation() {
        return string().when('VaccineProgramId', {
          is: value => [VaccineProgramIds.Flu, VaccineProgramIds.Covid].includes(value),
          then: schema => schema.required('Select where the vaccination is taking place'),
        });
      }
    },
    CareModelName: {
      get Validation(){
        return string()
      }
    },
    CareHomeOdsCode: {
      get Validation() {
        return string().when('CareModelId', {
          is: CareModelIds.CareHome,
          then: schema => schema.required('required').max(6, 'Maximum number of characters is 6')
        })
      }
    },

    CareHomeName: {
      get Validation() {
        return string().when('CareModelId', {
          is: (CareModelId) => {
            return CareModelId == CareModelIds.CareHome
          },
          then: schema => schema.required('Enter care home details')
        });
      }
    },

    CareHomeAddress: {
      get Validation() {
        return string().when('CareModelId', {
          is: CareModelIds.CareHome,
          then: schema => schema.required('required').max(400, 'Maximum number of characters is 400')
        })
      }
    },
    CareHomePostcode: {
      get Validation() {
        return string().when('CareModelId', {
          is: CareModelIds.CareHome,
          then: schema => schema.required('required').test('isValidPostcode', 'Invalid Postcode',
            function (value) {
              return ValidPostcode(value)
            })
        })
      }
    }
  }
];

export default function VaccinationAddEdit() {
  const user = useUser();

  const navigate = useNavigate();
  const { id, parentId, pageType, capitalisedPageType, isAddPage } = usePageDetails();

  const formFields = useMemo(() => {
    const obj = {};
    for (const fs of formSettings) {
      for (const f of Object.keys(fs)) {
        obj[f] = fs[f];
      }
    }
    return obj;
  }, []);

  const formObject = useMemo(() => {
    const obj = {};
    for (const f of Object.keys(formFields)) {
      obj[f] = '';
    }
    return obj;
  }, []);

  const validationSchema = useMemo(() => {
    const obj = {};
    for (const fs of formSettings) {
      for (const f of Object.keys(fs)) {
        obj[f] = fs[f]?.Validation;
      }
    }
    return object().shape(obj);
  }, []);


  const [saving, setSaving] = useState(false);
  const [allowEdit, setAllowEdit] = useState(isAddPage);
  const [options, setOptions] = useState(null);
  const [vaccines, setVaccines] = useState([]);
  const [step, setStep] = useState(isAddPage ? VaccinationSteps.Vaccine : VaccinationSteps.Confirm);
  const [stepChanged, setStepChanged] = useState(false);
  const [batchOptions, setBatchOptions] = useState(null);
  const [batchOptionsLoading, setBatchOptionsLoading] = useState(false);
  const [batchExists, setBatchExists] = useState(null);
  const [eligibilityTypeOptions, setEligibilityTypeOptions] = useState(null);
  const [activeFields, setActiveFields] = useState(getFields(step));
  const [initialValues, setInitialValues] = useState(formObject);
  const [batchExpiryDate, setBatchExpiryDate] = useState({ Day: '', Month: '', Year: '' });
  const [expectedDueDate, setExpectedDueDate] = useState({ Day: '', Month: '', Year: '' });
  const [canShowCareHomeDetails, setCanShowCareHomeDetails] = React.useState(false);
  const [nhsdOrganisation, setNhsdOrganisation] = React.useState(null);
  const [noAddressDetailsFound, setNoAddressDetailsFound] = React.useState(false);
  const [noMatchingRecord, setNoMatchingRecord] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const { vaccinationTitles, stepTitle } = useVaccinationTitle(step, capitalisedPageType);
  const [CareHomeCustomError, setCareHomeCustomError] = useState<string>(null);
  const [optionsLoading, setOptionsLoadingLoading] = useState(false);
  const [selectedConsentVaccineName, setSelectedConsentVaccineName] = useState<string>(null);

  const form = useFormik({ initialValues: initialValues as Vaccination, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleContinue });

  const { patient, lastCovidVaccinationDate, age } = useCachedPatient(form.values.PatientId);
  const legalMechanismHints = [{id:LegalMechanismIds.WI,hint:'Occupational health only'}];
  const handleCareModelChange = (event) => {
    const value = event.target.value;
    if (value && (value == CareModelIds.CareHome)) {
      setCanShowCareHomeDetails(true)
    }
    else {
      setCanShowCareHomeDetails(false)
      setNhsdOrganisation(null)
      removeCareHomeDetails()
    }
  }

  async function getSuggestions(value) {
    if (value.length > 2)
      return await vaccinatorLocationService.nhsdOrganisations$(value)
  }

  async function processValue(value: any) {
    var result = await getSuggestions(value);
    setCareHomeCustomError('')
    setSuggestions(result);
  }
  async function getAddressDetails(data: any) {
    return await vaccinatorLocationService.nhsdOrganisation$(data.OrgId) as NhsdOrganisation;
  }
  async function handleSuggestionClick(fieldName, data) {
    setCareHomeCustomError(null)
    setNoAddressDetailsFound(false)
    var result = await getAddressDetails(data)

    if (result) {
      let address = result.AddressLine1;
      if (result.AddressLine2) address += ', ' + result.AddressLine2;
      if (result.Town) address += ', ' + result.Town;

      form.setFieldValue(fieldName, result.Name)
      form.setFieldValue('CareHomeAddress', address)
      form.setFieldValue('CareHomeOdsCode', data.OrgId)
      form.setFieldValue('CareHomePostcode', data.PostCode ? data.PostCode: result.PostCode)
      setNhsdOrganisation(result)

    } else {
      form.setFieldValue('CareHomeName', '')
      form.setFieldValue('CareHomeOdsCode', '')
      form.setFieldValue('CareHomeAddress', '')
      form.setFieldValue('CareHomePostcode', '')

      setNhsdOrganisation(null)
    }
    setSuggestions([]);
  }

  const processConsentVaccineName = (vaccines) => {
    if (
      step > VaccinationSteps.Vaccine &&
      step < VaccinationSteps.Confirm &&
      ((form.values?.VaccineId && form.values?.VaccineId !== "") || form.values?.ConsentVaccineId) &&
      vaccines
    ) {
      const vaccineId = form.values?.VaccineId && form.values?.VaccineId !== "" 
                        ? form.values?.VaccineId 
                        : form.values?.ConsentVaccineId;
      const vaccineName = vaccines?.find((o) => o.VaccineId == vaccineId)?.Name;
      setSelectedConsentVaccineName(vaccineName);
    } else {
      setSelectedConsentVaccineName('');
    }
  };
  
  useEffect(() => {
    const fetchVaccines = async (siteId, vaccinationDate, vaccineProgramId) => {
      const vaccines =
        vaccineProgramId && step !== VaccinationSteps.Vaccine
          ? await vaccinationService.vaccinesWithActiveBatches$(
              siteId,
              vaccinationDate,
              vaccineProgramId
            )
          : await vaccinationService.vaccines$(siteId, vaccinationDate);

      setVaccines(vaccines);
      validateAndClearBatchDetails(vaccines);
      processConsentVaccineName(vaccines);
    };

    const siteId = form.values?.SiteId;
    if (!siteId) return;

    let vaccinationDate;

    vaccinationDate =
      step === VaccinationSteps.Vaccinate
        ? form.values?.VaccinationDate || new Date().toISOString().split("T")[0]
        : form?.values.VaccinationDate;

    if (step === VaccinationSteps.Vaccine) {
      vaccinationDate = new Date().toISOString().split("T")[0];
    }

    fetchVaccines(
      siteId,
      vaccinationDate,
      pageType === PageType.Edit || form?.values.VaccineProgramId
        ? form?.values.VaccineProgramId
        : null
    );
  }, [form.values?.SiteId, form.values?.VaccinationDate, step, pageType]);

  const validateAndClearBatchDetails=(vaccines) =>{
      const currentVaccineId = form.values?.VaccineId;
      const vaccineExists = vaccines.some(vaccine => vaccine.VaccineId.toString() === currentVaccineId);

      if (!vaccineExists) {
        removeBatchDetails();
        setSelectedConsentVaccineName(null)
      }
  }
 
  useEffect(() => {
    if (allowEdit) {
      form.setFieldValue('ConsentVaccineId', '');
      setEligibilityTypeOptions(options?.EligibilityTypes.filter(v => v.VaccineProgramId == form.values?.VaccineProgramId));

      form.setFieldValue('ExpectedDueDate', '');
      setTimeout(() => { form.setFieldTouched('ExpectedDueDate', false) });
      setExpectedDueDate({ Day: '', Month: '', Year: '' });
        form.setFieldError('ConsentVaccineId', undefined);
        form.setFieldTouched('ConsentVaccineId', false, false);
  
      updateBatchExists();
    }
  }, [form.values?.VaccineProgramId]);

  useEffect(() => {
    if (isAddPage) {
      if (form.values?.AssessmentOutcomeId === AssessmentOutcomeIds.VaccineNotGiven) {
        const fields = [...getFields(VaccinationSteps.Consent), ...getFields(VaccinationSteps.Vaccinate)];
        RemoveValues(form, fields);
      } else {
        RemoveValues(form, ['AssessmentNoVaccinationReasonId']);
      }
    }
  }, [form.values?.AssessmentOutcomeId]);

  useEffect(() => {
    if (isAddPage) {
      const fields = ['ConsentClinicianId', 'VaccinatingClinicianId']
      if (form.values?.LegalMechanismId === LegalMechanismIds.PGD) {
        const assessmentClinicianId = form.values?.AssessmentClinicianId;
        if (assessmentClinicianId) {
          for (const f of fields) {
            form.setFieldValue(f, assessmentClinicianId);
          }
        }
      } else {
        RemoveValues(form, fields);
      }
    }
  }, [form.values?.LegalMechanismId]);

  useEffect(() => {
    if (isAddPage && step === VaccinationSteps.Consent || step === VaccinationSteps.Vaccinate) {
      if (form.values?.LegalMechanismId === LegalMechanismIds.PGD) {
        const assessmentClinicianId = form.values?.AssessmentClinicianId;
        if (assessmentClinicianId) {
          form.setFieldValue('ConsentClinicianId', assessmentClinicianId);
          form.setFieldValue('VaccinatingClinicianId', assessmentClinicianId);
        }
      }
    }
  }, [step]);


  useEffect(() => {
    if (PageType.Edit === pageType && form.values.CareModelId === CareModelIds.CareHome.toString()) {
      const nhsdOrganisation: NhsdOrganisation = {
        Name: form?.values.CareHomeName,
        AddressLine1: form?.values.CareHomeAddress,
        PostCode: form?.values?.CareHomePostcode,
        OrgId: form?.values.CareHomeOdsCode,
        AddressLine2: '',
        Town: '',
        County: ''
      }
      setNhsdOrganisation(nhsdOrganisation)
      form.setFieldTouched("CareHomeName", false)
    }
  }, [form.values.CareModelId]);


  useEffect(() => {
    if (form.values?.BatchExpiryDate) {
      const dateArr = form.values?.BatchExpiryDate?.split('-');
      setBatchExpiryDate({ Year: dateArr[0], Month: dateArr[1], Day: dateArr[2] });
    }
  }, [form.values?.BatchExpiryDate]);

  useEffect(() => {
    if (isAddPage) {
      if (form.values?.Consented === RadioValues.false) {
        const fields = ['ConsentTypeId', ...getFields(VaccinationSteps.Vaccinate)];
        RemoveValues(form, fields);
      } else {
        RemoveValues(form, ['NoConsentReasonId']);
      }
    }
  }, [form.values?.Consented]);

  useEffect(() => {
    if (isAddPage) {
      if (form.values?.ConsentTypeId === ConsentTypeIds.PatientInformedConsent.toString()) {
        RemoveValues(form, ['NameOfPersonConsenting', 'RelationshipToPatient']);
      }
    }
    form.setFieldTouched("NameOfPersonConsenting", false)
    form.setFieldTouched("RelationshipToPatient", false)

    
  }, [form.values?.ConsentTypeId]);

  useEffect(() => {
    if (isAddPage) {
      if (form.values?.Vaccinated !== RadioValues.true) {
        const fields = ['VaccineId', 'VaccinationSiteId', 'BatchNumber', 'BatchExpiryDate', 'DoseAmount'];
        RemoveValues(form, fields);
      } else {
        RemoveValues(form, ['NoVaccinationReasonId']);
      }
    }
  }, [form.values?.Vaccinated, batchOptions]);


  useEffect(() => {
    if (allowEdit) {
      
      if (form.values?.ConsentVaccineId) {
        form.setFieldError('ConsentVaccineId', undefined);
        form.setFieldTouched('ConsentVaccineId', false, false);
      }
  
      updateBatchExists();
    }
  }, [form.values?.ConsentVaccineId]);
  
  

  useEffect(() => {
    if (allowEdit) {
      if (form.values?.Eligible !== RadioValues.true) {
        form.setFieldValue('EligibilityTypeId', '');
      }
    }
  }, [form.values?.Eligible]);

  useEffect(() => {
    if (allowEdit) {
      if (form.values?.EligibilityTypeId !== EligibilityTypeIds.HealthCareWorker) {
        form.setFieldValue('StaffRoleId', '');
      }
      if (form.values?.EligibilityTypeId !== EligibilityTypeIds.Pregnancy) {
        form.setFieldValue('ExpectedDueDate', '');
        setTimeout(() => { form.setFieldTouched('ExpectedDueDate', false) });
        setExpectedDueDate({ Day: '', Month: '', Year: '' });
      }
    }
  }, [form.values?.EligibilityTypeId]);


  useEffect(() => {
    if (allowEdit) {
      const vaccineId = form.values?.VaccineId;
      let doseAmount = '';
      if (vaccineId) {
        removeBatchDetails();
        doseAmount = vaccines?.find(v => v.VaccineId == vaccineId)?.DoseAmount;
      }
      form.setFieldValue('DoseAmount', doseAmount);
    }
  }, [form.values?.VaccineId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    vaccinationService.forceRefreshOptions()
    setOptionsLoadingLoading(true)

    if (isAddPage) {
      vaccinationService.options$().then(res => {
        setOptions(res);
        setOptionsLoadingLoading(false)
      });
    } else {
      vaccinationService.options$(true).then(res => {
        setOptions(res);
        setOptionsLoadingLoading(false)
      });
    }

    if (isAddPage) {
      form.setFieldValue('PatientId', parentId);
      form.setFieldValue('OrganisationId', user?.OrganisationId);

      const lastVaccination = vaccinationService.getLastVaccination();
      if (lastVaccination) {
        form.setFieldValue('SiteId', lastVaccination?.SiteId);
        form.setFieldValue('AssessmentClinicianId', lastVaccination?.AssessmentClinicianId);
        form.setFieldValue('AssessmentDate', lastVaccination?.AssessmentDate);
        form.setFieldValue('ConsentClinicianId', lastVaccination?.ConsentClinicianId);
        form.setFieldValue('VaccinationDate', lastVaccination?.VaccinationDate);
        form.setFieldValue('VaccinatingClinicianId', lastVaccination?.VaccinatingClinicianId);

      }
    } else {
      vaccinationService.get$(id).then(res => {
        setInitialValues(res);
        if (res.CareModelId === '5') {
          setCanShowCareHomeDetails(true)
        }
      });
    }
  }, []);

  function getFields(step) {
    const fields = formSettings[step];
    if (fields) return Object.keys(fields);
    return [];
  }

  const handleBack = () => {
    if (isAddPage || step > VaccinationSteps.Vaccinate) {
      changeStep(step - 1);
    } else {
      alert(`${VaccinationTitles.Consent} can't be edited.`);
    }
  };

  function handleContinue() {
    SetTouched(form, activeFields);
    if (!HasErrors(form.errors, activeFields)) {
      changeStep(step + 1);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    ScrollToTop()
  }

  function handleCustomChange(event) {
    const value = event.target.value;

    form.setFieldValue('CareHomeName', value);

    if (value) {
      const specialCharsRegex = /[!@#$%^&*+();,.?":{}|<>]/g;
      if (value?.trim() !== '' && !specialCharsRegex.test(value)) {
        processValue(value);
      } else {
        setSuggestions([]);
      }
    }
  }

  const handleSaveAndReturn = () => {
    SetTouched(form, activeFields);
    if (!HasErrors(form.errors, activeFields)) {
      save();
    }
  }

  const shouldPresentVaccinationAlert = (): boolean => {
    const assessmentOutcomeToNotVaccinate = form.values.AssessmentOutcomeId === AssessmentOutcomeIds.VaccineNotGiven;
    const patientNotVaccinated = form.values.Vaccinated === RadioValues.false;
    const patientDidNotConsent = form.values.Consented === RadioValues.false;
    return !assessmentOutcomeToNotVaccinate && !patientNotVaccinated && !patientDidNotConsent;
  }


  const save = async () => {
    setSaving(true);
    const vaccination = await vaccinationService.addOrEdit$(pageType, form.values).finally(() => setSaving(false));
    const presentVaccinationAlert = shouldPresentVaccinationAlert();
    navigate(`/patient/search/nhs-number`, {
      state: [{ PatientId: patient?.PatientId, RedirectToVaccination: presentVaccinationAlert, Name: patient?.FirstName + " " + patient?.LastName, Id: vaccination.VaccinationId, AuditDateTime: vaccination.AuditDateTime }],
    });

  }

  const onDeleteClick = async () => {
    setSaving(true);
    await vaccinationService.delete$(id).finally(() => setSaving(false));

    navigate(`/patient/${form.values.PatientId}`, {
      state: [{ ActionType: 'delete' }],
    });
  };

  const changeStep = (step) => {
    setStepChanged(true);
    setAllowEdit(true);
    const fields = getFields(step);
    setActiveFields(fields);
    setStep(step);
    window.scrollTo(0, 0);
  }

  const handleConsented = () => {
    const fields = ['ConsentTypeId', 'NameOfPersonConsenting', 'RelationshipToPatient'];
    if (form.values?.Consented === RadioValues.true) {
      RemoveValues(form, ['NoConsentReasonId']);
    } else {
      RemoveValues(form, fields);
    }
  }

  const updateBatchExists = async () => {
    setBatchExists(null);
    const vaccineProgramId = form.values.VaccineProgramId;
    if (vaccineProgramId) {
      const vaccineId = form.values.ConsentVaccineId;
      const siteId = form.values?.SiteId;
      if (vaccineId && siteId) {
        const date = new Date();
        setBatchExists(await batchService.batchExists$({
          SiteId: siteId,
          VaccineId: vaccineId,
          VaccinatedDate: date.toISOString().split('T')[0]
        }));
      }
    }
  }

  const removeBatchDetails = () => {
    RemoveValues(form, ['BatchNumber', 'BatchExpiryDate', 'DoseAmount']);
    setBatchExpiryDate({ Day: '', Month: '', Year: '' })

    if (pageType === PageType.Edit) {
      triggerBatchFieldsValidation()
      setTimeout(() => { form.setFieldTouched('VaccineId', true) });
    }
  }

  useEffect(() => {
    if (form.values.VaccinationDate && form.values.VaccineId && form.values.SiteId) {
      setBatchOptionsLoading(true);
      getBatchOptions();
      setBatchOptionsLoading(false);
    }
  }, [form.values.VaccinationDate, form.values.VaccineId, form.values.SiteId]);


  const getBatchOptions = async () => {
    const vaccinatedDate = form.values.VaccinationDate;
    if (vaccinatedDate) {
      const vaccineId = form.values.VaccineId;
      const siteId = form.values?.SiteId;
      if (vaccineId && siteId) {
        const batches = await batchService.getAvailableBatches({
          SiteId: siteId,
          VaccineId: vaccineId,
          VaccinatedDate: vaccinatedDate,
        });
        setBatchOptions(batches)
      }
    };
  }

  const setBatch = (e) => {
    const batchId = e.target.value;
    if (batchId) {
      const availableBatch = batchOptions?.find(b => b.BatchId == batchId);
      if (availableBatch) {
        form.setFieldValue('BatchNumber', availableBatch.BatchNumber, true);
        form.setFieldValue('BatchExpiryDate', RemoveTime(availableBatch.ExpiryDate), true);
        const doseAmount = vaccines?.find(v => v.VaccineId == form.values.VaccineId)?.DoseAmount;
        form.setFieldValue('DoseAmount', doseAmount, true);
        triggerBatchFieldsValidation()
      }
    } else {
      removeBatchDetails()
      triggerBatchFieldsValidation()
    }
  };

  const triggerBatchFieldsValidation = () => {
    setTimeout(() => { form.setFieldTouched('BatchNumber', true) });
    setTimeout(() => { form.setFieldTouched('BatchExpiryDate', true) });
    setTimeout(() => { form.setFieldTouched('DoseAmount', true) });
  }

  const onBatchClick = async () => {
    if (!form.values.VaccinationDate) {
      alert('Please select the date first!');
      return;
    }

    if (!form.values.VaccineId) {
      alert('Please select the vaccine first!');
      return;
    }

    if (!batchOptionsLoading && !batchOptions?.length) {
      alert('No batch found!');
      return;
    }

  };

  const showVaccineWarning = (vaccineId: string) => {
    if (vaccineId) {
      const vaccine = vaccines?.find(v => v.VaccineId == vaccineId && v.VaccineProgramId == VaccineProgramIds.Covid);
      if (vaccine) {
        return age < vaccine.MinAge || age > vaccine.MaxAge;
      }
    }
    return false;
  }

  const expectedDueDateChange = (e) => {
    const { name, value } = e.target;
    setExpectedDueDate({ ...expectedDueDate, [name]: value });
  };

  useEffect(() => {
    const year = expectedDueDate.Year;
    const month = expectedDueDate.Month?.padStart(2, '0') || '';
    const day = expectedDueDate.Day?.padStart(2, '0') || '';

    const formattedDate = year + '-' + month + '-' + day

    const isValidDate = moment(formattedDate, 'YYYY-MM-DD', true).isValid();

    if (isValidDate) {
      const date = new Date(`${year}-${month}-${day}`);
      form.setFieldValue('ExpectedDueDate', date);
    } else if (!expectedDueDate.Day && !expectedDueDate.Month && !expectedDueDate.Year) {
      form.setFieldValue('ExpectedDueDate', '');
    } else {
      form.setFieldValue('ExpectedDueDate', NaN);
    }
  }, [expectedDueDate]);

  function showRsvExpectedDueDateWarning() {
    if (form?.values.ExpectedDueDate && form?.values.AssessmentDate && form?.values.VaccineProgramId && form?.values.VaccineProgramId === VaccineProgramIds.RSV) {
      const maxDate = new Date(form?.values.AssessmentDate);
      maxDate.setDate(maxDate.getDate() + 82);

      const expectedDueDate = new Date(form?.values.ExpectedDueDate);
      return expectedDueDate > maxDate;
    }
    return false;
  }

  function showPertussisExpectedDueDateWarning() {
    if (form?.values.ExpectedDueDate && form?.values.AssessmentDate
      && form?.values.VaccineProgramId
      && form?.values.VaccineProgramId === VaccineProgramIds.Pertussis) {
      const minDate = new Date(form?.values.AssessmentDate);
      minDate.setDate(minDate.getDate() + 55);

      const maxDate = new Date(form?.values.AssessmentDate);
      maxDate.setDate(maxDate.getDate() + 166);

      const expectedDueDate = new Date(form?.values.ExpectedDueDate);
      return expectedDueDate <= minDate || expectedDueDate > maxDate;
    }
    return false;
  }

  const handleCareModelCustomError = () => {
    const { CareModelId, CareHomeOdsCode } = form.values;

    if (CareModelId === CareModelIds.CareHome.toString()) {
      const isCareHomeOdsCodeEmpty = !CareHomeOdsCode;
      const isCareHomeNameErrorUndefined = (form.errors.CareHomeName === undefined || form.errors.CareHomeName === '');

      if (isCareHomeOdsCodeEmpty && isCareHomeNameErrorUndefined ) {
        setCareHomeCustomError("Select care home");
        ScrollToTop();
        return false;
      } else {
        setCareHomeCustomError("");
        return true;
      }
    }

    return true;
  };


  const removeCareHomeDetails = () => {
    setSuggestions(null)
    setNhsdOrganisation(null);
    form.setFieldValue("CareHomeName", '')
    form.setFieldValue("CareHomeOdsCode", '')
    form.setFieldValue("CareHomeAddress", '')
    form.setFieldValue("CareHomePostCode",'')
    setCareHomeCustomError('')
    setTimeout(() => { form.setFieldTouched('CareHomeName', true) })
  }

return (
  <>
    {pageType != PageType.Delete && <>
      {step > VaccinationSteps.Vaccine ? <NhsBackLink onClick={handleBack}></NhsBackLink> : <NhsBackLink url={`/patient/${form.values.PatientId}`}></NhsBackLink>}
    </>}

    <div className='row flex-column-reverse flex-md-row'>
      <div className='col-md-8'>
        <NhsErrorSummary form={form} activeFields={activeFields} customError={CareHomeCustomError}></NhsErrorSummary>
        <fieldset className="nhsuk-fieldset">
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
            <h1 className="nhsuk-fieldset__heading">
              {stepTitle}
            </h1>
          </legend>

          <form onSubmit={(e) => { e.preventDefault(); form.handleSubmit(e) }}>
            {step == VaccinationSteps.Vaccine && (
              <>
                {optionsLoading ? <Loading /> : (
                  (options?.Sites?.length > 0) ? (
                    <>
                      <NhsOptionRadio name="SiteId" formFields={formFields} options={options?.Sites} formik={form}></NhsOptionRadio>
                      <NhsOptionRadio name="VaccineProgramId" formFields={formFields} options={options?.VaccinePrograms} formik={form}></NhsOptionRadio>
                      {form.values?.VaccineProgramId && (
                        <>
                          <div style={form.errors.ConsentVaccineId && form.touched.ConsentVaccineId ? { borderLeft: '4px solid red', paddingLeft: '10px' } : {}}>
                            <ConsentVaccineRadios name="ConsentVaccineId" batchExists={batchExists} formFields={formFields} optionValue={'VaccineId'} options={vaccines?.filter(v => v.Assigned && form.values.VaccineProgramId && v.VaccineProgramId == form.values.VaccineProgramId)} formik={form}></ConsentVaccineRadios>
                          </div>

                          {showVaccineWarning(form.values.ConsentVaccineId) && (
                            <div className="nhsuk-warning-callout">
                              <h3 className="nhsuk-warning-callout__label">
                                <span role="text">
                                  <span className="nhsuk-u-visually-hidden">Important: </span>
                                  Warning
                                </span>
                              </h3>
                              <p>
                                This vaccine may not be recommended for a person of this age. Please check before proceeding or refer to a prescriber for a Patient Specific Direction.
                              </p>
                            </div>
                          )}

                          {IncorrectInterval(lastCovidVaccinationDate, form.values.VaccineProgramId) && (
                            <div className="nhsuk-warning-callout">
                              <h3 className="nhsuk-warning-callout__label">
                                <span role="text">
                                  <span className="nhsuk-u-visually-hidden">Important: </span>
                                  Warning
                                </span>
                              </h3>
                              <p>You may have not reached the minimal interval between COVID-19 vaccine doses for this patient. This could depend on the clinical circumstances.
                                For vaccination guidance, visit <a href="https://www.gov.uk/government/publications/covid-19-the-green-book-chapter-14a" target="_blank" rel="noreferrer">
                                  COVID-19: The Green Book, chapter 14a (opens in a new tab).</a>
                              </p>
                            </div>
                          )}

                          <div className="nhsuk-form-group">
                            <Button type="button" className='nhsuk-button float-end' data-module="nhsuk-button" onClick={handleContinue}>Continue</Button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <p>You cannot record a vaccination yet, as there are no vaccines or batches set up.</p>
                      <p>You need to be an administrator to add vaccines.</p>
                    </div>
                  )
                )}
              </>
            )}

            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
              <h2 className='heading-2'>{selectedConsentVaccineName}</h2>
              </div>
            </div>
            
            {step === VaccinationSteps.Assessment && (
              <>

                {/* <h3>Check the patient is suitable for vaccination</h3> */}
                <NhsYesNo name="Eligible" formFields={formFields} formik={form}></NhsYesNo>
                {form.values?.Eligible === RadioValues.true && <NhsSelect autoSelectSingleOption={true} name="EligibilityTypeId" options={eligibilityTypeOptions} formFields={formFields} formik={form} disabled={eligibilityTypeOptions.length === 1}></NhsSelect>}
                {form.values?.EligibilityTypeId === EligibilityTypeIds.HealthCareWorker && <NhsSelect name="StaffRoleId" options={options?.StaffRoles} formFields={formFields} formik={form}></NhsSelect>}

                {form.values?.EligibilityTypeId === EligibilityTypeIds.Pregnancy &&
                  (form.values?.VaccineProgramId === VaccineProgramIds.RSV || form.values?.VaccineProgramId === VaccineProgramIds.Pertussis) &&
                  <>
                    <div className="nhsuk-form-group">
                      <fieldset className="nhsuk-fieldset" aria-describedby="Expected due date" role="group">
                        <legend className="nhsuk-fieldset__legend nhsuk-label--l mb-0">
                          <label htmlFor="ExpectedDueDate" className="nhsuk-label">{formFields['ExpectedDueDate']?.Label}</label>
                        </legend>

                        <div className="nhsuk-hint nhsuk-radios__hint ps-0" id="ExpectedDueDateHint">For example 05, 09, 2024</div>

                        {form.touched['ExpectedDueDate'] && form.errors['ExpectedDueDate'] &&
                          <span className="nhsuk-error-message" id="ExpectedDueDateError">
                            <span className="nhsuk-u-visually-hidden">Error:</span> {form.errors['ExpectedDueDate']}
                          </span>
                        }
                        <div className="nhsuk-date-input" id="ExpectedDueDate">
                          <div className="nhsuk-date-input__item">
                            <div className="nhsuk-form-group">
                              <label className="nhsuk-label nhsuk-date-input__label" htmlFor="ExpectedDueDateDay">Day</label>
                              <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2" value={expectedDueDate.Day} onChange={expectedDueDateChange}
                                maxLength={2} id="ExpectedDueDateDay" name="Day" type="number" pattern="[0-9]*" inputMode="numeric" />
                            </div>
                          </div>
                          <div className="nhsuk-date-input__item">
                            <div className="nhsuk-form-group">
                              <label className="nhsuk-label nhsuk-date-input__label" htmlFor="ExpectedDueDateMonth">Month</label>
                              <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2" value={expectedDueDate.Month} onChange={expectedDueDateChange}
                                maxLength={2} id="ExpectedDueDateMonth" name="Month" type="number" pattern="[0-9]*" inputMode="numeric" />
                            </div>
                          </div>
                          <div className="nhsuk-date-input__item">
                            <div className="nhsuk-form-group">
                              <label className="nhsuk-label nhsuk-date-input__label" htmlFor="ExpectedDueDateYear">Year</label>
                              <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4" value={expectedDueDate.Year} onChange={expectedDueDateChange}
                                maxLength={4} id="ExpectedDueDateYear" name="Year" type="number" pattern="[0-9]*" inputMode="numeric" />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    {showRsvExpectedDueDateWarning() &&
                      <div className="nhsuk-warning-callout">
                        <h3 className="nhsuk-warning-callout__label">
                          <span role="text">
                            <span className="nhsuk-u-visually-hidden">Important: </span>
                            Warning
                          </span>
                        </h3>
                        <p>
                          You have indicated the due date is {`${expectedDueDate.Day}/${expectedDueDate.Month}/${expectedDueDate.Year}`}.
                          This vaccine is not routinely recommended before 28 weeks of pregnancy.
                          For vaccination guidance, visit <a href="https://assets.publishing.service.gov.uk/media/669a5e37ab418ab05559290d/Green-book-chapter-27a-RSV-18_7_24.pdf" target="_blank" rel="noreferrer">
                            Respiratory syncytial virus (RSV): The Green Book, chapter 27a.</a>
                        </p>
                      </div>
                    }

                    {showPertussisExpectedDueDateWarning() &&
                      <div className="nhsuk-warning-callout">
                        <h3 className="nhsuk-warning-callout__label">
                          <span role="text">
                            <span className="nhsuk-u-visually-hidden">Important: </span>
                            Warning
                          </span>
                        </h3>
                        <p>
                          The vaccine is recommended between 16 to 32 weeks of pregnancy.
                          For guidance, visit <a href="https://assets.publishing.service.gov.uk/media/667dee0bc7f64e2342090116/Green_Book_Chapter_24_260624.pdf" target="_blank" rel="noreferrer">
                            Pertussis: the green book, chapter 24.</a>
                        </p>
                      </div>
                    }
                  </>
                }

                <NhsDateInput name="AssessmentDate" formFields={formFields} formik={form} min={minDate.toJSON().slice(0, 10)} max={new Date().toJSON().slice(0, 10)} ></NhsDateInput>

                <NhsOptionRadio name="LegalMechanismId" options={options?.LegalMechanisms} optionHints={legalMechanismHints} formFields={formFields} formik={form}></NhsOptionRadio>
                <NhsSelect name="AssessmentClinicianId" options={options?.Clinicians.filter(c => c.Roles.some(r => r == ClinicianRoleIds.AssessmentClinician))} optionValue={'ClinicianId'} formFields={formFields} formik={form}></NhsSelect>

                {form.values.VaccineProgramId === VaccineProgramIds.Covid &&
                  <details className="nhsuk-details">
                    <summary className="nhsuk-details__summary">
                      <span className="nhsuk-details__summary-text">
                        Screening considerations
                      </span>
                    </summary>

                    <div className="nhsuk-details__text">
                      <ul>
                        <li>Does the patient have a history of anaphylaxis or significant allergic reactions to any vaccines or their ingredients?</li>
                        <li>Has the patient had a serious adverse reaction after the COVID-19 vaccine?</li>
                        <li>Is the patient pregnant or could they be?</li>
                      </ul>
                    </div>
                  </details>
                }

                <NhsOptionRadio name="AssessmentOutcomeId" formFields={formFields} options={options?.AssessmentOutcomes} formik={form}></NhsOptionRadio>

                {form.values?.AssessmentOutcomeId === AssessmentOutcomeIds.VaccineNotGiven ?
                  <>
                    <NhsSelect name="AssessmentNoVaccinationReasonId" options={options?.NoVaccinationReasons} formFields={formFields} formik={form}></NhsSelect>
                    <NhsTextArea name="AssessmentComments" rows={3} formFields={formFields} formik={form}></NhsTextArea>
                    <Button type="button" className='nhsuk-button nhsuk-button--secondary float-start' data-module="nhsuk-button" onClick={handleSaveAndReturn}>Save and return</Button>
                  </>
                  :
                  <>
                    <NhsTextArea name="AssessmentComments" rows={3} formFields={formFields} formik={form}></NhsTextArea>
                    <div className="nhsuk-form-group">
                      <Button type="button" className='nhsuk-button float-end' data-module="nhsuk-button" onClick={handleContinue}>Continue</Button>
                    </div>
                  </>
                }
              </>
            )}

            {step === VaccinationSteps.Consent && (
              <>
                <NhsYesNo name="Consented" formFields={formFields} formik={form} lableYes='Yes, they consent' onClick={handleConsented}></NhsYesNo>

                {form.values?.Consented === RadioValues.false &&
                  <NhsSelect name="NoConsentReasonId" options={options?.NoConsentReasons} formFields={formFields} formik={form}></NhsSelect>
                }
                {form.values?.Consented === RadioValues.true && <>
                  <h3 className='pt-4'>Consent details</h3>
                      <NhsSelect name="ConsentTypeId" options={options?.ConsentTypes} formFields={formFields} formik={form}></NhsSelect>
                  {form.values?.ConsentTypeId && form.values?.ConsentTypeId !== '1' && <>
                        <NhsInput name="NameOfPersonConsenting" formFields={formFields} formik={form}></NhsInput>
                        <NhsInput name="RelationshipToPatient" formFields={formFields} formik={form}></NhsInput>
                  </>}
                </>}

                <h3 className='pt-4'>Clinician details</h3>
                <NhsSelect name="ConsentClinicianId"
                  options={form?.values.LegalMechanismId === LegalMechanismIds.PGD ? options?.Clinicians : options?.Clinicians.filter(c => c.Roles.some(r => r == ClinicianRoleIds.ConsentClinician))}
                  optionValue={'ClinicianId'} formFields={formFields} formik={form} disabled={form?.values.LegalMechanismId === LegalMechanismIds.PGD}></NhsSelect>

                {form.values?.Consented === RadioValues.false &&
                  <Button type="button" className='nhsuk-button nhsuk-button--secondary float-start' data-module="nhsuk-button" onClick={handleSaveAndReturn}>Save and return</Button>
                }

                {form.values?.Consented === RadioValues.true &&
                  <Button type="button" className='nhsuk-button float-end' data-module="nhsuk-button" onClick={handleContinue}>Continue</Button>
                }
              </>
            )}

            {step === VaccinationSteps.Vaccinate && (
              <>
                <NhsYesNo name="Vaccinated" formFields={formFields} formik={form} disabled={isAddPage ? false : true}></NhsYesNo>
                {form.values?.Vaccinated === RadioValues.false &&
                  <NhsSelect name="NoVaccinationReasonId" options={options?.NoVaccinationReasons} formFields={formFields} formik={form}></NhsSelect>
                }

                <NhsDateInput name="VaccinationDate" formFields={formFields} formik={form} min={minDate.toJSON().slice(0, 10)} max={new Date().toJSON().slice(0, 10)}></NhsDateInput>

                {(form?.values.VaccineProgramId === VaccineProgramIds.Covid || form?.values.VaccineProgramId === VaccineProgramIds.Flu) && (
                  <>
                    <div className="nhsuk-form-group">
                      <label htmlFor="CareModelId" className="nhsuk-label">Where is the vaccination taking place?</label>
                      <div className="nhsuk-hint nhsuk-radios__hint ps-0" id="CareModelHint">This is needed for payment and reporting</div>

                      <NhsOptionRadio name="CareModelId" options={options?.CareModels} formFields={formFields} formik={form} onChange={(e) => { handleCareModelChange(e); form.handleChange(e); }} onBlur={(e) => { handleCareModelChange(e); form.handleBlur(e); }}></NhsOptionRadio>
                    </div>

                    {canShowCareHomeDetails ?
                      <div>
                        <h3>
                          Care home details
                        </h3>

                        {noAddressDetailsFound ?
                          <span className="nhsuk-error-message" id="CareHomeAddressError">
                            <span className="nhsuk-u-visually-hidden">Error:</span> Care home address details not found
                          </span> : null
                        }

                        {!form.values.CareHomeOdsCode ?
                          <>
                            <NhsAutocompleteInput
                              label={'Name'}
                              aria-label="Search by name or ODS code"
                              hint={'Search by name or ODS code'}
                              name="CareHomeName"
                              noMatchingRecord={noMatchingRecord}
                              selectFromDropDown={null}
                              formik={form}
                              customError={CareHomeCustomError}
                              handleCustomChange={handleCustomChange} />
                            {form?.values?.CareHomeName?.length > 2 &&
                            <NhsAutocompleteSuggestions
                            inputValue={form.values.CareHomeName}
                            name='CareHomeName' 
                            suggestions={suggestions}
                            handleOnClick={handleSuggestionClick}
                            nodatafoundname="organisation" />
                            }
                          </>
                          :
                          <div>
                            <p>
                              {toTitleCase(form.values.CareHomeName)}
                            </p>
                            <p>
                              {form.values.CareHomeAddress?.split(',').map((part, index) => (
                                <span key={index}>
                                  {toTitleCase(part)}
                                  <br />
                                </span>
                              ))}
                              <span>{form.values.CareHomePostcode}</span>
                            </p>
                            <p>ODS code {form.values.CareHomeOdsCode}</p>
                            <a href='#' onClick={(event) => {
                              event.preventDefault();
                              removeCareHomeDetails();
                            }}>Remove</a>
                          </div>
                        }

                      </div>
                      :
                      ''
                    }
                  </>
                )}

                {form.values?.Vaccinated === RadioValues.true && <>
                  <h3 className='pt-4'>Vaccine details</h3>

                  <NhsOptionRadio onChange={(e)=> { 
                    const vaccineName = vaccines?.find((o) => o.VaccineId == e.target.value)?.Name
                    setSelectedConsentVaccineName(vaccineName)
                    form.handleChange(e) 
                  }} name="VaccineId" optionValue={'VaccineId'} options={vaccines?.filter(v => v.Assigned && form.values.VaccineProgramId && v.VaccineProgramId == form.values.VaccineProgramId)} formFields={formFields} formik={form}></NhsOptionRadio>

                  {showVaccineWarning(form.values.VaccineId) &&
                    <div className="nhsuk-warning-callout">
                      <h3 className="nhsuk-warning-callout__label">
                        <span role="text">
                          <span className="nhsuk-u-visually-hidden">Important: </span>
                          Warning
                        </span>
                      </h3>
                      <p>
                        This vaccine may not be recommended for a person of this age. Please check before proceeding or refer to a prescriber for a Patient Specific Direction.
                      </p>
                    </div>
                  }
                  {IncorrectInterval(lastCovidVaccinationDate, form.values.VaccineProgramId) &&
                    <div className="nhsuk-warning-callout">
                      <h3 className="nhsuk-warning-callout__label">
                        <span role="text">
                          <span className="nhsuk-u-visually-hidden">Important: </span>
                          Warning
                        </span>
                      </h3>
                      <p>You may have not reached the minimal interval between COVID-19 vaccine doses for this patient. This could depend on the clinical circumstances.
                        For vaccination guidance, visit <a href="https://assets.publishing.service.gov.uk/media/65d50a1f2197b2001d7fa70e/Greenbook-chapter-14a-20240220.pdf" target="_blank" rel="noreferrer">
                          COVID-19: The Green Book, chapter 14a.</a>
                      </p>
                    </div>
                  }
                  <NhsSelect name="VaccinationSiteId" options={options?.VaccinationSites} formFields={formFields} formik={form}></NhsSelect>

                  <div className="nhsuk-form-group">
                    <label htmlFor="BatchNumber" className="nhsuk-label">{formFields['BatchNumber']?.Label}</label>

                    {form.touched.BatchNumber && form.errors.BatchNumber ? (
                      <span className="nhsuk-error-message" id="BatchNumberError">
                        <span className="nhsuk-u-visually-hidden">Error:</span> {form.errors['BatchNumber']}
                      </span>
                    ) : null}

                    {batchOptions?.length ? (
                      <select id="BatchNumber" className='nhsuk-select' onChange={setBatch}>
                        <option key="-1" value="">--Please select--</option>
                        {batchOptions?.map((o, i) => (
                          <option selected={form.values?.BatchNumber === o.BatchNumber}
                            key={i} value={o.BatchId}>
                            {o.BatchNumber} - {IsoToUkDate(o.ExpiryDate)}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div>
                        <select
                          id="BatchNumber"
                          name="BatchNumber"
                          className="nhsuk-select"
                          value={form.values.BatchNumber}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          disabled={batchOptionsLoading}
                          onClick={onBatchClick}
                        >
                          <option key="0" value="">
                            {batchOptionsLoading ? 'Loading...' : '--Please select--'}
                          </option>
                          {!batchOptionsLoading &&
                            batchOptions?.map((batch, index) => (
                              <option key={index + 1} value={batch.BatchNumber}>
                                {batch.BatchNumber}
                              </option>
                            ))}
                        </select>
                      </div>

                    )}
                  </div>


                  <div className="nhsuk-form-group">
                    <fieldset className="nhsuk-fieldset" aria-describedby="Batch expiry date" role="group">

                      {form.touched.BatchExpiryDate && form.errors.BatchExpiryDate &&
                        <span className="nhsuk-error-message" id="BatchExpiryDateError">
                          <span className="nhsuk-u-visually-hidden">Error:</span> {form.errors['BatchExpiryDate']}
                        </span>
                      }

                      <legend className="nhsuk-fieldset__legend nhsuk-label--l mb-0">
                        <label htmlFor={'BatchExpiryDate'} className="nhsuk-label">{formFields['BatchExpiryDate']?.Label}</label>
                      </legend>

                      <div className="nhsuk-date-input" id={'BatchExpiryDate'}>
                        <div className="nhsuk-date-input__item">
                          <div className="nhsuk-form-group">
                            <label className="nhsuk-label nhsuk-date-input__label" htmlFor="BatchExpiryDate-day">Day</label>
                            <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2" value={batchExpiryDate.Day}
                              id="BatchExpiryDate-day" name="BatchExpiryDate-day" type="text" pattern="[0-9]*" inputMode="numeric" disabled={true} />
                          </div>
                        </div>
                        <div className="nhsuk-date-input__item">
                          <div className="nhsuk-form-group">
                            <label className="nhsuk-label nhsuk-date-input__label" htmlFor="BatchExpiryDate-month">Month</label>
                            <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2" value={batchExpiryDate.Month}
                              id="BatchExpiryDate-month" name="BatchExpiryDate-month" type="text" pattern="[0-9]*" inputMode="numeric" disabled={true} />
                          </div>
                        </div>
                        <div className="nhsuk-date-input__item">
                          <div className="nhsuk-form-group">
                            <label className="nhsuk-label nhsuk-date-input__label" htmlFor="BatchExpiryDate-year">Year</label>
                            <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4" value={batchExpiryDate.Year}
                              id="BatchExpiryDate-year" name="BatchExpiryDate-year" type="text" pattern="[0-9]*" inputMode="numeric" disabled={true} />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                    <NhsInput name="DoseAmount" formFields={formFields} formik={form} disabled className="nhsuk-select"></NhsInput>
                  </>}

                <h3 className='pt-2 mb-0'>Clinician details</h3>
                <NhsSelect name="VaccinatingClinicianId"
                  options={form?.values.LegalMechanismId === LegalMechanismIds.PGD ? options?.Clinicians : options?.Clinicians.filter(c => c.Roles.some(r => r == ClinicianRoleIds.VaccinatingClinician))}
                  optionValue={'ClinicianId'} formFields={formFields} formik={form} disabled={form?.values.LegalMechanismId === LegalMechanismIds.PGD}></NhsSelect>


                {form.values?.Vaccinated === RadioValues.false &&
                  <Button type="button" className='nhsuk-button nhsuk-button--secondary float-start' data-module="nhsuk-button" onClick={handleSaveAndReturn}>Save and return</Button>
                }

                <NhsTextArea name="VaccinationComments" rows={3} formFields={formFields} formik={form}></NhsTextArea>


                {form.values?.Vaccinated === RadioValues.true && (
                <>
                  {form.values?.VaccineProgramId === VaccineProgramIds.Covid && (
                    <>
                      <div>
                        <h3 className='pt-2 mb-3'>Post vaccination advice</h3>
                        <p style={{ fontSize: '1rem' }}>
                          Did you explain what the patient should expect after their vaccination?<br />
                          find out about <a href="https://www.nhs.uk/vaccinations/covid-19-vaccine/" target="_blank" rel="noreferrer">COVID-19 vaccinations on NHS.UK (opens in new tab)</a>.
                        </p>
                      </div>

                      <div style={{ borderLeft: '7px solid #005EB8', paddingLeft: '16px', marginBottom: '16px', fontSize: '0.875rem', paddingTop: '10px', paddingBottom: '1px' }}>
                        <p style={{ fontSize: '1rem' }}>
                          If the patient has an adverse reaction, you (or the patient) can complete a&nbsp;
                          <a
                            href="https://yellowcard.mhra.gov.uk/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ paddingLeft: '4px', paddingRight: '4px' }}
                          >
                            Yellow Card Report (opens a new tab)
                          </a>
                          &nbsp;to make medicines safer.
                        </p>
                      </div>
                    </>
                  )}

                  {form.values?.VaccineProgramId === VaccineProgramIds.RSV && (
                    <>
                      <div>
                        <h3 className='pt-2 mb-3'>Post vaccination advice</h3>
                        <p style={{ fontSize: '1rem' }}>
                          Did you explain what the patient should expect after their vaccination?<br />
                          Find out about <a href="https://www.nhs.uk/vaccinations/rsv-vaccine/" target="_blank" rel="noreferrer">RSV vaccinations on NHS.UK (opens in a new tab)</a>.
                        </p>
                      </div>

                      <div style={{ borderLeft: '7px solid #005EB8', paddingLeft: '16px', marginBottom: '16px', fontSize: '0.875rem', paddingTop: '10px', paddingBottom: '1px' }}>
                        <p style={{ fontSize: '1rem' }}>
                          If the patient has an adverse reaction, you (or the patient) can complete a&nbsp;
                          <a
                            href="https://yellowcard.mhra.gov.uk/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ paddingLeft: '4px', paddingRight: '4px' }}
                          >
                            Yellow Card Report (opens a new tab)
                          </a>
                          &nbsp;to make medicines safer.
                        </p>
                      </div>
                    </>
                  )}

                  {form.values?.VaccineProgramId === VaccineProgramIds.Pertussis && (
                    <>
                      <div>
                        <h3 className='pt-2 mb-3'>Post vaccination advice</h3>
                        <p style={{ fontSize: '1rem' }}>
                          Did you explain what the patient should expect after their vaccination?<br />
                          Find out about <a href="https://www.nhs.uk/pregnancy/keeping-well/whooping-cough-vaccination/" target="_blank" rel="noreferrer">Pertussis (whooping cough) vaccinations during pregnancy on NHS.UK (opens in new tab)</a>.
                        </p>
                      </div>

                      <div style={{ borderLeft: '7px solid #005EB8', paddingLeft: '16px', marginBottom: '1px', fontSize: '0.875rem', paddingTop: '10px', paddingBottom: '1px'  }}>
                        <p style={{ fontSize: '1rem' }}>
                          If the patient has an adverse reaction, you (or the patient) can complete a&nbsp;
                          <a
                            href="https://yellowcard.mhra.gov.uk/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ paddingLeft: '4px', paddingRight: '4px' }}
                          >
                            Yellow Card Report (opens a new tab)
                          </a>
                          &nbsp;to make medicines safer.
                        </p>
                      </div>
                    </>
                  )}

                  {form.values?.VaccineProgramId === VaccineProgramIds.Flu && (
                    <>
                      <div>
                        <h3 className='pt-2 mb-3'>Post vaccination advice</h3>
                        <p style={{ fontSize: '1rem' }}>
                          Did you explain what the patient should expect after their vaccination?<br />
                          Find out about <a href="https://www.nhs.uk/vaccinations/flu-vaccine/" target="_blank" rel="noreferrer">Flu vaccinations on NHS.UK (opens in new tab)</a>.
                        </p>
                      </div>

                      <div style={{ borderLeft: '7px solid #005EB8', paddingLeft: '16px', marginBottom: '16px', fontSize: '0.875rem' }}>
                        <p style={{ fontSize: '1rem' }}>
                          If the patient has an adverse reaction, you (or the patient) can complete a&nbsp;
                          <a
                            href="https://yellowcard.mhra.gov.uk/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ paddingLeft: '4px', paddingRight: '4px' }}
                          >
                            Yellow Card Report (opens a new tab)
                          </a>
                          &nbsp;to make medicines safer.
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}


                <div className="nhsuk-form-group">
                  <Button type="button" className='nhsuk-button float-end' data-module="nhsuk-button" onClick={() => {
                    const customValidationCheck = handleCareModelCustomError()
                    if (customValidationCheck) {
                      handleContinue()
                    }
                  }

                  }>Continue</Button>
                </div>
              </>
            )}

            {step === VaccinationSteps.Confirm && (
              <>
                <span className='actionwidth'>
                  {patient && <>
                    <h3 className="pb-0 mb-0">Patient details</h3>
                    <dl className="nhsuk-summary-list">
                      <NhsSummary label={'Name'} value={patient.FirstName + ' ' + patient.LastName}></NhsSummary>
                      <NhsSummary label={'NHS number'} value={patient.NhsNumber}></NhsSummary>
                      <NhsSummary label={'Date of birth'} value={`${DateToLongFormat(patient.DateOfBirth)} (aged ${GetAge(patient.DateOfBirth)})`}></NhsSummary>
                      <NhsSummary label={'Gender'} value={patient.Gender}></NhsSummary>
                      <NhsSummary label={'Telephone'} value={patient.Telephone}></NhsSummary>
                      <NhsSummary label={'Address'} value={patient.Address + `${patient.Postcode && ', ' + patient.Postcode}`}></NhsSummary>
                    </dl>
                  </>}

                  {form.values && <>
                    <h3 className="pb-0 mb-0">Choose a vaccine</h3>
                    <dl className="nhsuk-summary-list mb-1">
                      <NhsOptionSummary name={'SiteId'} label={'Delivery team'} options={options?.Sites} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'VaccineProgramId'} label={'Vaccine'} options={options?.VaccinePrograms} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'VaccineId'} label={'Product'} optionValue={'VaccineId'} options={vaccines} formFields={formFields} formik={form}></NhsOptionSummary>
                    </dl>
                    {isAddPage &&
                      <div className='mb-4'><button className='nhs-link-button' onClick={() => changeStep(VaccinationSteps.Vaccine)}>Change the vaccine details</button></div>
                    }

                    <h3 className="pb-0 mb-0">{VaccinationTitles.Assessment}</h3>
                    <dl className="nhsuk-summary-list mb-1">
                      <NhsYesNoSummary name={'Eligible'} label={'Eligible'} formFields={formFields} formik={form}></NhsYesNoSummary>
                      <NhsOptionSummary name={'EligibilityTypeId'} label={'Eligibility type'} options={options?.EligibilityTypes} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'StaffRoleId'} options={options?.StaffRoles} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsDateSummary name={'ExpectedDueDate'} formFields={formFields} formik={form}></NhsDateSummary>
                      <NhsDateSummary name={'AssessmentDate'} formFields={formFields} formik={form} displayWithDay={true}></NhsDateSummary>
                      <NhsOptionSummary name={'LegalMechanismId'} options={options?.LegalMechanisms} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'AssessmentClinicianId'} options={options?.Clinicians} optionValue={'ClinicianId'} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'AssessmentOutcomeId'} options={options?.AssessmentOutcomes} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsSummary name={'AssessmentComments'} label={'Comments'} formFields={formFields} formik={form}></NhsSummary>
                    </dl>
                    {isAddPage &&
                      <div className='mb-4'><button className='nhs-link-button' onClick={() => changeStep(VaccinationSteps.Assessment)}>Change assess the patient page details</button></div>
                    }

                    <h3 className="pb-0 mb-0">{VaccinationTitles.Consent}</h3>
                    <dl className="nhsuk-summary-list mb-1">
                      <NhsYesNoSummary name={'Consented'} label={'Consent?'} formFields={formFields} formik={form}></NhsYesNoSummary>
                      <NhsOptionSummary name={'ConsentTypeId'} options={options?.ConsentTypes} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsSummary name={'NameOfPersonConsenting'} formFields={formFields} formik={form}></NhsSummary>
                      <NhsSummary name={'RelationshipToPatient'} formFields={formFields} formik={form}></NhsSummary>
                      <NhsOptionSummary name={'ConsentClinicianId'} options={options?.Clinicians} optionValue={'ClinicianId'} formFields={formFields} formik={form}></NhsOptionSummary>
                    </dl>
                    {isAddPage &&
                      <div className='mb-4'><button className='nhs-link-button' onClick={() => changeStep(VaccinationSteps.Consent)}>Change record consent page details</button></div>
                    }

                    <h3 className="pb-0 mb-0">{VaccinationTitles.Vaccinate}</h3>
                    <dl className="nhsuk-summary-list mb-1">
                      <NhsYesNoSummary name={'Vaccinated'} label={'Vaccinated?'} formFields={formFields} formik={form}></NhsYesNoSummary>
                      <NhsDateSummary name={'VaccinationDate'} label={'Date'} formFields={formFields} formik={form} displayWithDay={true}></NhsDateSummary>
                      <NhsOptionSummary name={'CareModelId'} label={'Care Model'} options={options?.CareModels} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'VaccineProgramId'} label={'Vaccine'} options={options?.VaccinePrograms} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'VaccineId'} label={'Product'} optionValue={'VaccineId'} options={vaccines} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsSummary name={'BatchNumber'} label={'Batch'} value={form.values?.BatchNumber} formFields={formFields} formik={form}></NhsSummary>
                      <NhsDateSummary name={'BatchExpiryDate'} label={'Expiry date'} formFields={formFields} formik={form}></NhsDateSummary>
                      <NhsSummary name={'DoseAmount'} formFields={formFields} formik={form}></NhsSummary>
                      <NhsOptionSummary name={'VaccinationSiteId'} label={'Site'} options={options?.VaccinationSites} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsOptionSummary name={'VaccinatingClinicianId'} options={options?.Clinicians} optionValue={'ClinicianId'} formFields={formFields} formik={form}></NhsOptionSummary>
                      <NhsSummary name={'VaccinationComments'} label={'Comments'} formFields={formFields} formik={form}></NhsSummary>
                    </dl>
                    {pageType != PageType.Delete &&
                      <div className='mb-4'><button className='nhs-link-button' onClick={() => changeStep(VaccinationSteps.Vaccinate)}>Change vaccinate page details</button></div>
                    }
                  </>}

                  {pageType == PageType.Delete &&
                    <div className="nhsuk-warning-callout">
                      <h3 className="nhsuk-warning-callout__label">
                        <span role="text">
                          <span className="nhsuk-u-visually-hidden">Important: </span>
                          The record is ready to be deleted
                        </span>
                      </h3>
                      <p>Are you sure you want to delete this record?</p>
                    </div>
                  }

                  <div className="nhsuk-form-group">
                    <Button type="button" className='nhsuk-button nhsuk-button--reverse float-start' data-module="nhsuk-button" onClick={() => navigate(`/patient/${form.values.PatientId}`)}>Cancel</Button>
                    {saving ? <Spinner className='float-end'></Spinner> :
                      <>
                        {pageType == PageType.Delete ?
                          <Button type="button" className='nhsuk-button nhsuk-button--secondary float-end' data-module="nhsuk-button" onClick={onDeleteClick}>Delete</Button> :
                          <Button type="button" className='nhsuk-button float-end' data-module="nhsuk-button" disabled={!isAddPage && !stepChanged} onClick={save}>Confirm details and save</Button>
                        }
                      </>
                    }
                  </div>
                </span>
              </>
            )}
          </form>
        </fieldset>
      </div>

      <div className='col-md-4'>
        <VaccinationProgress currentPage={step} pages={vaccinationTitles}></VaccinationProgress>
        {step < 4 && patient && <PatientDetails patient={patient}></PatientDetails>}
      </div>
    </div>
  </>
);
}
