import { appAxios } from '../../../_shared/axios';
import { RoleIds } from '../../../components/user/user.enums';
import { User } from '../../../components/user/user.models';
import { Clinician } from '../../../components/vaccination/vaccination.models';

export class UserManagementService {
  private basePath = 'api/UserManagement/';

  async getUsers$(organisationId: number): Promise<User[]> {
    return (await appAxios.get(`${this.basePath}GetUsers?organisationId=${organisationId}`)).data;
  }

  async getOrganisationUsersByRole$(organisationId: number, role: RoleIds): Promise<User[]> {
      return (await appAxios.get(`${this.basePath}GetOrganisationUsersByRole?organisationId=${organisationId}&roleId=${role}`)).data;
  }

  async getClinicians$(organisationId: number): Promise<Clinician[]> {
    return (await appAxios.get(`${this.basePath}GetClinicians?organisationId=${organisationId}`)).data;
  }

  async checkEmailExists$(email: string): Promise<boolean> {
    return (await appAxios.get(`${this.basePath}EmailExists?email=${encodeURIComponent(email)}`)).data;
  }

  async checkValidDomain$(email: string): Promise<boolean> {
    return (await appAxios.get(`${this.basePath}AllowedEmailDomain?email=${encodeURIComponent(email)}`)).data;
  }

  async getUser$(id: string): Promise<User> {
    return await appAxios.get(`${this.basePath}get?id=${id}`);
  }

  async addOrEdit$(model: User, title: string, isLeadUser: boolean = false) : Promise<User> {
    return (await appAxios.post(`${this.basePath}${title}?isLeadUser=${isLeadUser}`, model)).data;
  }

  static get instance() { return userManagementService }
}

const userManagementService = new UserManagementService();

export default userManagementService;