import React from 'react';
import { Link } from 'react-router-dom';

export default function AccessDenied() {
  return (
    <div className="alert alert-warning row" role="alert">
      <div className="col-md mt-2">
        <h6>You do not have permission to access this resource!</h6>
      </div>
      <div className="col-md-2">
        <Link to ={'/home'} className="btn btn-link">Back to Home</Link>
      </div>
    </div>
  );
}

