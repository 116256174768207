import React, { useRef, useCallback } from 'react';
import './custom.scss';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import UserGuard from './components/user/user.guard';
import Footer from './components/_layout/Footer';
import Header from './components/_layout/Header';
import UserProvider from './components/user/UserProvider';
import EnvironmentBanner from './components/_layout/EnvironmentBanner';
import CookieBanner from './components/_layout/cookie/CookieBanner';
import TimeOutWarning from './_shared/components/session/TimeOutWarning';

export default function App() {
  const mainContentRef = useRef<HTMLDivElement>(null);

  const setSkipLinkRef = useCallback((node: HTMLAnchorElement) => {
    if (node) {
      node.addEventListener('click', (event) => {
        event.preventDefault();
        if (mainContentRef.current) {
          mainContentRef.current.focus();
          mainContentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }, []);

  return (
    <UserProvider>
      <CookieBanner />
      <a ref={setSkipLinkRef} className="nhsuk-skip-link" href="#maincontent" tabIndex={0}>
        Skip to main content
      </a>
      <Header />
      <EnvironmentBanner />
      <div className="nhsuk-width-container">
        <main ref={mainContentRef} className="nhsuk-main-wrapper" id="maincontent" role="main" tabIndex={-1}>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, userGuard, ...rest } = route;
              return (
                <Route
                  key={index}
                  {...rest}
                  element={
                    <>
                      {userGuard ? <UserGuard element={element} /> : element}
                      <TimeOutWarning />
                    </>
                  }
                />
              );
            })}
          </Routes>
        </main>
      </div>
      <Footer />
    </UserProvider>
  );
}
