export enum SiteVaccinesPaths {
    AddSite = '/vaccines/add/choose-site',
    AddVaccine = '/vaccines/add/choose-vaccine',
    AddBatch = '/vaccines/add/batch',
    EditBatch = '/vaccines/edit/batch',
    DepleteBatch = '/vaccines/batch/deplete/',
    ConfirmSiteVaccineBatch = '/vaccines/add/confirm',
    SiteVaccinesList = '/vaccines',
    SiteVaccineBatchesList = '/site-vaccine-batches',
}

export enum SiteVaccinesPageTitles {
    AddSite = 'Add site - Batch management',
    AddVaccine = 'Choose vaccine - Batch management',
    AddBatch = 'Add batch - Batch management',
    EditBatch = 'Edit batch - Batch management',
    DepleteBatch = 'Deplete batch - Batch management',
    ConfirmSiteVaccineBatch = 'Check and confirm - Batch management',
    SiteVaccineBatchesList = 'Vaccine batches - Batch management',
    SiteVaccinesList = 'Vaccines - Batch management',
}

export enum SiteVaccinesContants {
    COVID19_ID = 1,
    FLU = 2
}

export enum VaccinePrograms {
    Covid = 'COVID-19',
    Flu = 'Flu',
    CovidFlu = 'CovidFlu'
}

export enum SiteVaccinesAnalyticsPageNames {
    PrimaryCategory = "vaccines",
}

export enum AddSiteAnalyticsSubCategories {
    SubCategory1 = "site",
    SubCategory2 = "add"
}

export enum AddVaccineAnalyticsSubCategories {
    SubCategory1 = "vaccine",
    SubCategory2 = "add"
}

export enum AddBatchAnalyticsSubCategories {
    SubCategory1 = "batch",
    SubCategory2Add = "add",
    SubCategory2Edit = "edit"
}

export enum AddVaccineBatchAnalyticsSubCategories {
    SubCategory2 = "add",
    SubCategory3 = "check",
}

export enum SiteVaccineBatchesPageNames {
    PrimaryCategory = "vaccines",
    SubCategory1 = "batches",
    SubCategory2 = "batch",
    SubCategory3 = "reactivate",
}


export enum AddSiteVaccineBatchesAnalyticsSubCategories {
    SubCategory2 = "add",
    SubCategory3 = "check",
}

export enum EditSiteVaccineBatchAnalyticsSubCategories {
    SubCategory2 = "edit",
    SubCategory3 = "check",
}
export enum AddSiteVaccineBatchAnalyticsSubCategories {
    SubCategory1 = "add",
    SubCategory2 = "check"
}

export enum DepleteBatchAnalyticsSubCategories {
    SubCategory1 = "batch",
    SubCategory2Deplete = "deplete",
    SubCategory2Undepelete = "undeplete"
}