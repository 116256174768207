import React, { ReactNode } from 'react';
import { useUser } from '../../../components/user/UserProvider';
import AccessDenied from '../../../components/user/pages/AccessDenied';

interface AccessControlProps {
  requiredRoles: string[];
  matchAllRoles?: boolean; // prop to determine if all roles should match or just one
  showUnauthorisedMessage?: boolean; // prop to determine whether to show the "Unauthorized" message

  children: ReactNode;
}

const AccessControl: React.FC<AccessControlProps> = ({ requiredRoles, matchAllRoles, showUnauthorisedMessage = true, children }) => {
  const user = useUser();

  if (!user || !user.Roles) {
    return showUnauthorisedMessage ? <AccessDenied /> : null;
  }

  const hasRequiredRoles = requiredRoles.length === 0
    ? true
    : matchAllRoles
      ? requiredRoles.every(role => user.Roles.includes(role))
      : requiredRoles.some(role => user.Roles.includes(role));

  if (hasRequiredRoles) {
    return <>{children}</>;
  } else {
    return showUnauthorisedMessage ? <AccessDenied /> : null;
  }
};

export default AccessControl;
