import React, {  } from 'react';
import { Link } from 'react-router-dom';
import useAnalytics from '../../analytics/hooks/useAnalytics';
import useDocumentTitle from '../../../_shared/hooks/useDocumentTitle';
import { CookieConfirmationAnalyticsPageNames, CookiePolicyPageTitles } from './cookie.enums';

export default function CookieConfirmation() { 
    useAnalytics(["service", CookieConfirmationAnalyticsPageNames.PrimaryCategory]);
    useDocumentTitle(CookiePolicyPageTitles.CookieConfirmation);
  return (
    <>     
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-two-thirds">      
          <h1>       
            Your cookie settings have been saved      
          </h1>
        </div>
      </div>

      <article>
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">
            <section>
              <p>We'll save your settings for a year.</p>
              <p>We'll ask you if you're still OK with us using cookies when either:</p>
              <ul>
                <li>it's been a year since you last saved your settings</li>
                <li>we add any new cookies or change the cookies we use</li>
              </ul>
              <p>You can also change your settings at any time using our <Link to="/cookie/policy">cookies page</Link>.</p>
            </section>
          </div>
        </div>
      </article>  
    </>  
  )
}
