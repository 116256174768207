export enum VaccinationLocationPaths {
    VaccinationLocationSelect = '/vaccinator-location/select'
}

export enum VaccinationLocationPageTitle {
    Location = 'Your location'
}

export enum PrimaryRoleIds {
    GpPractice = "RO177",
    CareHome = "RO101"
}

export enum CareModelIds {
    CareHome = 5,
    ResidentialFacility = 6
}

export enum VaccinatorLocationAnalyticsPageNames {
    PrimaryCategory = 'select-location'
}