export enum UserPaths {
  ApiLogin = '/api/user/login',
  ApiLogout = '/api/user/logout',
  UserRegister = '/user/register',
  AccessDenied = '/access-denied',
  AuthenticationFailed = '/authentication-failed',
  Profile = '/profile',
  Login = '/login',
  UserOrganisation = '/user/organisation',
  ServiceUnavailable = '/service-unavailable'
}

export enum RoleIds {
  Administrator = '1',
  LeadAdministrator = '2',
  Recorder = '3',
  RegionalAdministrator = '4',
}

export enum PolicyIds {
  CanManageUsers = "1",
  CanDeactivateUsers = "4"
}

export enum AccountStatusIds
{
  Pending = 1,
  Enabled = 2,
  Disabled = 3
}

export enum UserProfilePrimaryCategory {
    PrimaryCategory = "user-profile"
}

export enum UserPageTitles {
    UserProfile = "User Profile",
    Login = "Login"
}

export enum LoginAnalyticsPageNames {
    PrimaryCategory = 'login'
}
