import React from 'react';

export default function NhsSummary({name = null, formFields = null, formik = null, label = null, value = null, showChange = null, onChange = null}) {
  const val = value ?? formik?.values[name];
  return (
    <>
      { val && 
        <div className="nhsuk-summary-list__row">
          <dt className="nhsuk-summary-list__key">
            { label ?? formFields[name]?.Label }
          </dt>

          <dd className="nhsuk-summary-list__value">
            { val }
          </dd>

          <dd className="nhsuk-summary-list__actions">
            { showChange && onChange && <button className='nhs-link-button' onClick={onChange}>Change</button>}          
          </dd>     
        </div>
      }
    </> 
  )
}