import { useMemo } from 'react';
import { object } from 'yup';
import { useFormik } from 'formik';

export default function useForm(formSettings, handleSubmit) {
  const { initialValues, validationSchema} = useMemo(() => {
    const initialValues = {};
    const validationSchema = {};
    
    for (const f of Object.keys(formSettings)) {
      initialValues[f] = formSettings[f]?.Value;
      validationSchema[f] = formSettings[f]?.Validation;
    }
    return { initialValues, validationSchema: object().shape(validationSchema)};
  }, []);
  
  const form = useFormik({ initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleSubmit });
 
  return {
    form
  };
}
