import {appAxios} from "../../axios";

export class SessionService {

    private basePath = "api/session/";

    // This buffer is to prevent the scenario where the front-end timestamp is out of sync with the actual session expiry
    // set by the back-end, e.g. due to the time taken for the response to reach the front-end.
    private bufferInMinutes = 1;
    private actualSessionDuration = 16;
    private sessionLengthInMinutes = this.actualSessionDuration - this.bufferInMinutes;

    private localStorageKey = "sessionExpirationTime";

    refreshSessionTimestamp () {
        const sessionDuration = this.getSessionDurationInMilliseconds();
        const expirationTime = new Date().getTime() + sessionDuration;
        localStorage.setItem(this.localStorageKey, expirationTime.toString());
    }

    getSessionTimestamp () {
        return localStorage.getItem(this.localStorageKey);
    }

    async extendSession () {
        const response = await appAxios.get(`${this.basePath}ExtendSession`);
        return response.data;
    }

    private getSessionDurationInMilliseconds() {
        return this.sessionLengthInMinutes * 60 * 1000;
    }

    static get instance() { return sessionService }
}

const sessionService = new SessionService();

export default sessionService;
