import * as React from "react"
import './NhsAutocompleteSuggestions.scss';
import { Option } from "../../../shared.models";

export default function NhsAutocompleteSuggestions({ inputValue, suggestions, handleOnClick, ...props }) {

    const renderSuggestionsNotFound = () => {
        if (suggestions?.length === 0  && inputValue.length > 2) {
            return (
                <li className='organisationNotFound' aria-selected="false" aria-disabled="true">
                    No {props.nodatafoundname} found
                </li>
            );
        }
    }

    return (
        <>
            {suggestions &&
                <>
                    {suggestions.map((suggestion: Option, index) => (
                        <div className="siteRows" key={index} onClick={() => handleOnClick(props.name, suggestion)}>
                            <HighlightMatch suggestion={suggestion.PrefixedName} inputValue={inputValue} />
                        </div>
                    ))}
                    {renderSuggestionsNotFound()}
                </>         
            }
        </>
    );
}

function HighlightMatch({ suggestion, inputValue }) {
    if (suggestion) {
        const index = suggestion?.indexOf(inputValue);
        if (index === -1) return <>{suggestion}</>;

        const beforeMatch = suggestion.slice(0, index);
        const match = suggestion.slice(index, index + inputValue.length);
        const afterMatch = suggestion.slice(index + inputValue.length);

        return (
            <>
                {beforeMatch}
                <strong>{match}</strong>
                {afterMatch}
            </>
        );
    }
}