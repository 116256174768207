import { useEffect } from 'react';
import { useCookie } from '../../_layout/cookie/CookieProvider';
import { Segments } from '../analytics.models';
import { RadioValues } from '../../../_shared/shared.enums';

export default function useAnalytics(segments: Segments) {
  const { cookieConsent } = useCookie();

  let pageName = "nhs:ravs";
  for (const segment of segments) {
    if (segment) {
      pageName += `:${segment}`;
    }
  }

  useEffect(() => {
    if (cookieConsent === RadioValues.true) {
      window.digitalData = {
        page: {
          pageInfo: {
            pageName: pageName
          },
        }
      };
    }
  }, [pageName, cookieConsent]);
}
