import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Col, NavLink, Row, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import optionService from '../../_shared/option.service';
import patientService from './patient.service';
import { object, string } from 'yup';
import NhsInput from '../../_shared/components/form/NhsInput';
import NhsSelect from '../../_shared/components/form/NhsSelect';
import { Patient } from './patient.models';
import { ValidPostcode } from '../../_shared/shared.validation';
import { HandleFocusChange, IsoToUkDate, ScrollToBottom } from '../../_shared/shared.functions';
import NhsActionLink from '../../_shared/components/NHSUK/NhsActionLink';
import { PatientSearchAnalyticsPrimaryCategory, PatientPageTitles, PatientSearchRecordAnayticsPageName } from './patient.enums';
import useAnalytics from '../analytics/hooks/useAnalytics';
import useDocumentTitle from '../../_shared/hooks/useDocumentTitle';

const formFields = {
    FirstName: {
        Label: "First name"
    },
    LastName: {
        Label: "Last name"
    },
    GenderId: {
        Label: "Gender (Optional)"
    },
    DateOfBirth: {
        Label: "Date of birth (Optional)"
    },
    Postcode: {
        Label: "Full postcode (Optional)"
    }
}

export default function PatientSearchRecords() {
    useAnalytics([
        "service",
        PatientSearchAnalyticsPrimaryCategory.PrimaryCategory,
        PatientSearchRecordAnayticsPageName.SubCategory1,
    ]);
    useDocumentTitle(PatientPageTitles.PatientRecordSearch);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [searchValues, setSearchValues] = useState(null as Patient);
    const [patients, setPatients] = useState([] as Patient[]);

    const [genderOptions, setGenderOptions] = useState([]);
    const [dateOfBirthError, setDateOfBirthError] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            FirstName: '',
            LastName: '',
            DateOfBirth_1: '',
            DateOfBirth_2: '',
            DateOfBirth_3: '',
            DateOfBirth: '',
            GenderId: '',
            Gender: '',
            Postcode: ''
        },
        validationSchema: object({
            FirstName: string().min(2, "Minimum of two characters").required('Enter the first name'),
            LastName: string().required('Enter the last name'),
            GenderId: string(),
            Gender: string(),
            Postcode: string().test('isValidPostcode', 'Enter the full postcode in the correct format',
                function (value) {
                    if (value) return ValidPostcode(value);
                    else
                        return true;
                }),
        }),
        enableReinitialize: true,
        onSubmit: async (values) => {
            setSubmitButtonClicked(false);

            let patient = values as unknown as Patient;

            patient.FirstName = patient.FirstName.trim();
            patient.LastName = patient.LastName.trim();

            if (values.DateOfBirth_1 || values.DateOfBirth_2 || values.DateOfBirth_3) {
                let day = values.DateOfBirth_1;
                let month = values.DateOfBirth_2;
                let year = values.DateOfBirth_3;

                if (/^(d{1,2})$/.test(day)) {
                    setDateOfBirthError(true);
                    return;
                }

                if (/^(d{1,2})$/.test(month)) {
                    setDateOfBirthError(true);
                    return;
                }

                if (/^(d{2,4})$/.test(year)) {
                    setDateOfBirthError(true);
                    return;
                }

                const today = new Date();
                const allowedYear = today.getFullYear();
                if (!year || Number(year) > allowedYear) {
                    setDateOfBirthError(true);
                    return;
                }

                const inputDate = new Date(year + '-' + month + '-' + day);
                const currentDate = new Date();
                if (inputDate > currentDate) {
                    setDateOfBirthError(true);
                    return;
                }

                if (Number(month) < 1 || Number(month) > 12) {
                    setDateOfBirthError(true);
                    return;
                }

                if (Number(day) < 1 || Number(day) > 31) {
                    setDateOfBirthError(true);
                    return;
                }

                if ((Number(month) === 4 || Number(month) === 6 || Number(month) === 9 || Number(month) === 11) && Number(day) === 31) {
                    setDateOfBirthError(true);
                    return;
                }

                if (Number(month) === 2) {
                    const isLeapYear = (Number(year) % 4 === 0 && Number(year) % 100 !== 0 || Number(year) % 400 === 0);

                    if ((Number(day) > 29 || (Number(day) === 29 && !isLeapYear))) {
                        setDateOfBirthError(true);
                        return;
                    }
                }
            } 

            if (values.DateOfBirth_1 && values.DateOfBirth_2 && values.DateOfBirth_3) {
                let dateOfBirth_1 = values.DateOfBirth_1.toString().length === 1 ? '0' + values.DateOfBirth_1.toString() : values.DateOfBirth_1.toString();
                let dateOfBirth_2 = values.DateOfBirth_2.toString().length === 1 ? '0' + values.DateOfBirth_2.toString() : values.DateOfBirth_2.toString();
                patient.DateOfBirth = values.DateOfBirth_3.toString() + '-' + dateOfBirth_2 + '-' + dateOfBirth_1;
            }
            else {
                patient.DateOfBirth = '';
            }

            if (values.GenderId)
                patient.Gender = genderOptions.find(g => Number(g.Id) === Number(values.GenderId))?.Name;

            setSearchValues(patient);
            setLoading(true);
            setPatients(await patientService.ravsSearchh$(patient).finally(() => { setLoading(false); setSubmitButtonClicked(true); ScrollToBottom(); }));
        }
    });

    useEffect(() => {
        const getGenderOptions = async () => {
            setGenderOptions(await optionService.getCachedOption$('Genders'));
        }
        getGenderOptions();
    }, []);

    const recordVaccination = (patient) => {
        navigate(`/patient/${patient.PatientId}`);
    }

    const handleWarnings = (e, data) => {
        if (data.DateOfBirth) {
            setDateOfBirthError(false);
            formik.setFieldError('DateOfBirth', null);
        }
    }

    return (
        <fieldset className="nhsuk-fieldset">
            <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                <h1 className="nhsuk-fieldset__heading">
                    Find a patient
                </h1>
            </legend>

            <div className="nhsuk-tabs" data-module="nhsuk-tabs">
                <ul className="nhsuk-tabs__list" aria-label="Patient search by local records tab">
                    <li className="nhsuk-tabs__list-item">
                        <NavLink tag={Link} to="/patient/search/nhs-number">By NHS number</NavLink>
                    </li>
                    <li className="nhsuk-tabs__list-item">
                        <NavLink tag={Link} to="/patient/search/pds">By demographics</NavLink>
                    </li>
                    <li className="nhsuk-tabs__list-item nhsuk-tabs__list-item--selected">
                        <NavLink href="#" active>By local records</NavLink>
                    </li>
                </ul>

                    <div className="nhsuk-tabs__panel pt-0" id="records">
                        <br />
                        <h2 className="nhsuk-table__caption">Search your local records</h2>
                        <h3 className="nhsuk-label">Enter at least the first and last name to find a patient's saved vaccination record in the system.</h3>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col md="5">
                                    <NhsInput name="FirstName" formFields={formFields} formik={formik}></NhsInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="5">
                                    <NhsInput name="LastName" formFields={formFields} formik={formik}></NhsInput>
                                </Col>
                            </Row>
                        <NhsSelect aria-label="Gender field optional" name="GenderId" formFields={formFields} formik={formik} options={genderOptions}></NhsSelect>
                            <Row>
                                <Col md="4">
                                <NhsInput aria-label="Postcode field optional" onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} name="Postcode" maxLength={8} formFields={formFields} type="Postcode" formik={formik}></NhsInput>
                                </Col>
                            </Row>
                            <>
                                <div className="nhsuk-form-group">
                                    <fieldset className="nhsuk-fieldset" aria-describedby="DateOfBirth-hint" role="group">
                                        <legend className="nhsuk-fieldset__legend">
                                            Date of birth
                                        </legend>
                                        <div className="nhsuk-hint" id="DateOfBirth-hint">
                                            For example, 31 03 1980
                                        </div>
                                        
                                        <div className="nhsuk-date-input" id="DateOfBirth">
                                            {dateOfBirthError &&
                                                <span key="DateOfBirthIdErrorRequiredSpan" className="nhsuk-error-message" id="DateOfBirthRequiredError">
                                                    <span key="DateOfBirthErrorRequiredSpan" className="nhsuk-u-visually-hidden">Error:</span>Enter the date of birth in the correct format
                                                </span>
                                            }

                                            <div className="nhsuk-date-input__item">
                                                <div className="nhsuk-form-group">
                                                    <input className="nhsuk-input nhsuk-date-input__input "
                                                    id="DateOfBirthHidden"
                                                    name="DateOfBirth"
                                                    type="hidden"
                                                    value={formik.values.DateOfBirth_3 + '-' + formik.values.DateOfBirth_2 + '-' + formik.values.DateOfBirth_1}
                                                    onChange={(e) => { formik.setFieldTouched('DateOfBirth'); }}
                                                    onBlur={(e) => { formik.handleBlur(e); formik.setFieldTouched('DateOfBirth'); }}
                                                    />
                                                </div>
                                            </div>
                                            <br></br>
                                            <br></br>
                                            <div className="nhsuk-date-input__item">
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="DateOfBirth_1">
                                                        Day
                                                    </label>

                                                    {formik.touched.DateOfBirth_1 && formik.errors.DateOfBirth_1 ?
                                                        <span key="DateOfBirth_1IdErrorSpan" className="nhsuk-error-message" id="DateOfBirth_1IdError">
                                                            <span key="DateOfBirthDay_1IdVisuallyHiddenErrorSpan" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.DateOfBirth_1}
                                                        </span> : null
                                                    }

                                                    <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                                                        id="DateOfBirth_1" name="DateOfBirth_1" type="number" inputMode="numeric"
                                                        value={formik.values.DateOfBirth_1}
                                                        placeholder="DD"
                                                        maxLength={2}
                                                        onChange={(e) => { formik.handleChange(e); handleWarnings(e, { DateOfBirth: true }); formik.setFieldTouched('DateOfBirth'); HandleFocusChange(e); }}
                                                        onBlur={(e) => { formik.handleBlur(e); handleWarnings(e, { DateOfBirth: true }); formik.setFieldTouched('DateOfBirth'); HandleFocusChange(e); }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="nhsuk-date-input__item">
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="DateOfBirth_2">
                                                        Month
                                                    </label>

                                                    {formik.touched.DateOfBirth_2 && formik.errors.DateOfBirth_2 ?
                                                        <span key="DateOfBirth_2IdErrorSpan" className="nhsuk-error-message" id="DateOfBirth_2IdError">
                                                            <span key="DateOfBirth_2IdVisuallyHiddenErrorSpan" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.DateOfBirth_2}
                                                        </span> : null
                                                    }

                                                    <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                                                        id="DateOfBirth_2" name="DateOfBirth_2" type="number" inputMode="numeric"
                                                        value={formik.values.DateOfBirth_2}
                                                        placeholder="MM"
                                                        maxLength={2}
                                                        onChange={(e) => { formik.handleChange(e); handleWarnings(e, { DateOfBirth: true }); formik.setFieldTouched('DateOfBirth'); HandleFocusChange(e); }}
                                                        onBlur={(e) => { formik.handleBlur(e); handleWarnings(e, { DateOfBirth: true }); formik.setFieldTouched('DateOfBirth'); HandleFocusChange(e); }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="nhsuk-date-input__item">
                                                <div className="nhsuk-form-group">
                                                    <label className="nhsuk-label nhsuk-date-input__label" htmlFor="DateOfBirth_3">
                                                        Year
                                                    </label>

                                                    {formik.touched.DateOfBirth_3 && formik.errors.DateOfBirth_3 ?
                                                        <span key="DateOfBirth_3IdErrorSpan" className="nhsuk-error-message" id="DateOfBirth_3IdError">
                                                            <span key="DateOfBirth_3IdVisuallyHiddenErrorSpan" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.DateOfBirth_3}
                                                        </span> : null
                                                    }

                                                    <input className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
                                                        id="DateOfBirth_3" name="DateOfBirth_3" type="number" inputMode="numeric"
                                                        value={formik.values.DateOfBirth_3}
                                                        placeholder="YYYY"
                                                        maxLength={4}
                                                        onChange={(e) => { formik.handleChange(e); handleWarnings(e, { DateOfBirth: true }); formik.setFieldTouched('DateOfBirth'); HandleFocusChange(e); }}
                                                        onBlur={(e) => { formik.handleBlur(e); handleWarnings(e, { DateOfBirth: true }); formik.setFieldTouched('DateOfBirth'); HandleFocusChange(e); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </>
                            {loading ? <Spinner></Spinner> : <Button type="submit" className='nhsuk-button' data-module="nhsuk-button">Search</Button>}
                        </form>
                    </div>
            </div>

            {!loading ?
                <>
                    {patients && patients?.length > 0 ?
                        <>
                            <h3>Displaying {patients.length === 1 ? <>{patients.length} match</> : <>{patients.length} matches</>}  for "<b>{searchValues.FirstName}, {searchValues.LastName}{searchValues.Gender ? <>, {searchValues.Gender}</>:''}{searchValues.Postcode ? <>, {searchValues.Postcode}</>:''}{searchValues.DateOfBirth ? <>, {IsoToUkDate(searchValues.DateOfBirth)}</>:''}</b>"</h3>
                            <>
                                <table role="table" className="nhsuk-table-responsive">
                                    {/*role="rowgroup"*/}
                                    <thead className="nhsuk-table__head">
                                        <tr role="row">
                                            <th role="columnheader" className="" scope="col">
                                                Personal details
                                            </th>
                                            <th role="columnheader" className="" scope="col">
                                                NHS number
                                            </th>
                                            <th role="columnheader" className="" scope="col">
                                                Date of birth
                                            </th>
                                            <th role="columnheader" className="" scope="col">
                                                Address
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="nhsuk-table__body">
                                        {patients.map((patient, index) =>
                                            <tr key={index + 'trPatient'} role="row" className="nhsuk-table__row">
                                                <td key={index + 'tdPatientPersonalDetails'} role="cell" className="nhsuk-table__cell">
                                                    <React.Fragment key={index + 'tdFragmentPatientPersonalDetails'}><><span key={index + 'SpanHeadertdFragmentPatientPersonalDetails'} className="nhsuk-table-responsive__heading">Personal details</span>
                                                        <NhsActionLink text={patient.FirstName + ' ' + patient.LastName} onClick={() => recordVaccination(patient)}></NhsActionLink>
                                                    </>
                                                    </React.Fragment>
                                                </td>

                                                <td key={index + 'tdPatientNhsNumber'} role="cell" className="nhsuk-table__cell">
                                                    <React.Fragment key={index + 'tdFragmentPatientNhsNumber'}><><span key={index + 'SpanHeadertdFragmentPatientNhsNumber'} className="nhsuk-table-responsive__heading">NHS number</span>
                                                        {patient.NhsNumber}
                                                    </>
                                                    </React.Fragment>
                                                </td>

                                                <td key={index + 'tdPatientDateOfBirth'} role="cell" className="nhsuk-table__cell">
                                                    <React.Fragment key={index + 'tdFragmentDateOfBirth'}><><span key={index + 'SpanHeadertdFragmentDateOfBirth'} className="nhsuk-table-responsive__heading">Date of brith</span>
                                                        {IsoToUkDate(patient.DateOfBirth)}
                                                    </>
                                                    </React.Fragment>
                                                </td>

                                                <td key={index + 'tdPatientAddress'} role="cell" className="nhsuk-table__cell">
                                                    <React.Fragment key={index + 'tdFragmentPatientAddress'}><><span key={index + 'SpanHeadertdFragmentPatientAddress'} className="nhsuk-table-responsive__heading">Address</span>
                                                        {patient.Address && patient.Address + ','} {patient.Postcode}
                                                    </>
                                                    </React.Fragment>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                        </>
                        : <>
                            {submitButtonClicked && patients?.length === 0 ?
                                <>
                                    <h3 className="mb-4">No results found for "<b>{searchValues?.FirstName}, {searchValues?.LastName}{searchValues?.Gender ? <>, {searchValues?.Gender}</> : ''}{searchValues?.Postcode ? <>, {searchValues?.Postcode}</> : ''}{searchValues?.DateOfBirth ? <>, {IsoToUkDate(searchValues?.DateOfBirth)}</> : ''}</b>". Try to refine your search using our <Link className='nhsuk-card__link' to="/search-tips">search tips</Link> or create a new patient.</h3>
                                </> : ''}
                        </>
                    }
                </>
                : ''
            }
        </fieldset>
    );
};