import { Table } from 'nhsuk-react-components';
import React from 'react';
import { User } from '../user/user.models';
import { Loading } from '../../_shared/components/Loading';
import { AccountStatusIds } from '../user/user.enums';
import { UserStatusConverter } from '../../_shared/shared.functions';
import { matchRoleIdWithRole } from '../user/pages/ManageUsersRoot';
import { Link } from 'react-router-dom';

const OrganisationUserList: React.FC<{
    users: User[];
    isLoading: boolean;
    onClickEdit: (userId: number) => void;
    deactivatedUserCount: number;
}> = ({ users, isLoading, onClickEdit, deactivatedUserCount }) => {
    const [hoveredField, setHoveredField] = React.useState<number | null>(null);

    const renderUserRows = () => {
        const rows = [];
        for (let i = 0; i < users.length; i++) {
            const user = users[i];

            if (user.AccountStatusId === AccountStatusIds.Disabled) {
                continue;
            }
            rows.push(
                <Table.Row key={user.UserId} role="row">
                    <Table.Cell role="cell">
                        {user.FirstName} {user.LastName}{' '}
                        {user.Clinical && '(Clinician)'}
                    </Table.Cell>
                    <Table.Cell role="cell">{user.Email}</Table.Cell>
                    <Table.Cell role="cell">
                        {matchRoleIdWithRole(user.Roles)}
                    </Table.Cell>
                    <Table.Cell role="cell">
                        {UserStatusConverter(user.LastLoginDate)}
                    </Table.Cell>
                    <Table.Cell role="cell">
                        <button
                            className={`anchor-style ${hoveredField === i ? 'hovered-color' : 'default-color'}`}
                            onMouseEnter={() => setHoveredField(i)}
                            onMouseLeave={() => setHoveredField(null)}
                            onClick={() => onClickEdit(user.UserId)}
                            aria-label={`Change details for ${user.FirstName} ${user.LastName} ${user.Clinical ? 'Clinician' : ''}`}
                        >
                            Change
                            <span className="nhsuk-u-visually-hidden">
                                role for {`${user.FirstName} ${user.LastName}`}
                            </span>
                        </button>
                    </Table.Cell>
                </Table.Row>,
            );
        }
        return rows;
    };

    return (
        <>
            {isLoading && <Loading />}
            {users && !isLoading && (
                <Table role="table">
                    <caption className="nhsuk-table__caption table-caption-fix">
                        Users
                    </caption>
                    <Table.Head role="rowgroup">
                        <Table.Row role="row">
                            <Table.Cell role="columnheader">Name</Table.Cell>
                            <Table.Cell role="columnheader">
                                Email address
                            </Table.Cell>
                            <Table.Cell role="columnheader">
                                Permission level
                            </Table.Cell>
                            <Table.Cell role="columnheader">Status</Table.Cell>
                            <Table.Cell role="columnheader">
                                <span className="nhsuk-u-visually-hidden">
                                    Edit user
                                </span>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body role="rowgroup">{renderUserRows()}</Table.Body>
                </Table>
            )}

            { deactivatedUserCount ? 
              <p className='mt-3'>
                <Link to={`/manage-users/deactivated-users`}>
                  View {deactivatedUserCount} deactivated user{deactivatedUserCount > 1 && 's'}
                </Link>
              </p>   
              :
              <></>    
            }
        </>
    );
};

export default OrganisationUserList;
