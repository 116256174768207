import React, { FC, useState } from "react";
import { Formik, Field, Form } from "formik";
import {
  Radios,
  Button,
  DateInput,
  ErrorSummary,
} from "nhsuk-react-components";
import * as Yup from "yup";
import { ReportsPages, SelectedDates } from "../user/pages/Reports";

type DateInputValue = {
  day: string;
  month: string;
  year: string;
};

interface SelectedDatesInput {
  dateRangeOption: string;
  fromDate: DateInputValue;
  toDate: DateInputValue;
}

export const ReportDateSelection: FC<{
  setSelectedDate: React.Dispatch<React.SetStateAction<SelectedDates | null>>;
  handlePageChange: (isForward: boolean) => void;
  selectedDates: SelectedDates | null;
}> = ({ handlePageChange, setSelectedDate, selectedDates }) => {
  const [showErrorSummary, setShowErrorSummary] = useState(false);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);

  const isValidDate = ({ day, month, year }: DateInputValue) => {
    if (!day || !month || !year) return false;
    const date = new Date(`${year}-${month}-${day}`);
    return (
      date.getFullYear() === parseInt(year, 10) &&
      date.getMonth() + 1 === parseInt(month, 10) &&
      date.getDate() === parseInt(day, 10)
    );
  };

  const formatDateToInputValue = (date: Date | null): DateInputValue => {
    if (!date) return { day: "", month: "", year: "" };
    return {
      day: String(date.getDate()).padStart(2, "0"),
      month: String(date.getMonth() + 1).padStart(2, "0"),
      year: String(date.getFullYear()),
    };
  };

  const initialValues: SelectedDatesInput = {
    dateRangeOption: selectedDates?.dateRangeOption || "",
    fromDate: formatDateToInputValue(selectedDates?.fromDate || null),
    toDate: formatDateToInputValue(selectedDates?.toDate || null),
  };

  const validationSchema = Yup.object({
    dateRangeOption: Yup.string().required("Please select a date range option"),
    fromDate: Yup.object({
      day: Yup.string().when("dateRangeOption", {
        is: "custom",
        then: (schema) =>
          schema
            .required("Day is required")
            .matches(/^[0-9]+$/, "Day must be a number")
            .max(2, "Day is too long"),
      }),
      month: Yup.string().when("dateRangeOption", {
        is: "custom",
        then: (schema) =>
          schema
            .required("Month is required")
            .matches(/^[0-9]+$/, "Month must be a number")
            .max(2, "Month is too long"),
      }),
      year: Yup.string().when("dateRangeOption", {
        is: "custom",
        then: (schema) =>
          schema
            .required("Year is required")
            .matches(/^[0-9]+$/, "Year must be a number")
            .length(4, "Year must be 4 digits"),
      }),
    })
      .test("fromDate", "From date must be valid", function (value) {
        return isCustomDateSelected
          ? isValidDate(value as DateInputValue)
          : true;
      })
      .test(
        "fromDate",
        "From date must be less than to date",
        function (value) {
          const { dateRangeOption, toDate } = this.parent;
          if (dateRangeOption === "custom") {
            const from = new Date(`${value.year}-${value.month}-${value.day}`);
            const to = new Date(`${toDate.year}-${toDate.month}-${toDate.day}`);
            if (from && to) {
              return from <= to;
            }
          }
          return true;
        },
      )
      .test("fromDate", "From date cannot be in the future", function (value) {
        if (this.parent.dateRangeOption === "custom") {
          const from = new Date(`${value.year}-${value.month}-${value.day}`);
          const today = new Date();
          from.setHours(0, 0, 0, 0);
          today.setHours(0, 0, 0, 0);
          return from <= today;
        }
        return true;
      }),
    toDate: Yup.object({
      day: Yup.string().when("dateRangeOption", {
        is: "custom",
        then: (schema) =>
          schema
            .required("Day is required")
            .matches(/^[0-9]+$/, "Day must be a number")
            .max(2, "Day is too long"),
      }),
      month: Yup.string().when("dateRangeOption", {
        is: "custom",
        then: (schema) =>
          schema
            .required("Month is required")
            .matches(/^[0-9]+$/, "Month must be a number")
            .max(2, "Month is too long"),
      }),
      year: Yup.string().when("dateRangeOption", {
        is: "custom",
        then: (schema) =>
          schema
            .required("Year is required")
            .matches(/^[0-9]+$/, "Year must be a number")
            .length(4, "Year must be 4 digits"),
      }),
    })
      .test("toDate", "To date must be valid", function (value) {
        return isCustomDateSelected
          ? isValidDate(value as DateInputValue)
          : true;
      })
      .test(
        "toDate",
        "To date must be greater than from date",
        function (value) {
          const { dateRangeOption, fromDate } = this.parent;
          if (dateRangeOption === "custom") {
            const from = new Date(
              `${fromDate.year}-${fromDate.month}-${fromDate.day}`,
            );
            const to = new Date(`${value.year}-${value.month}-${value.day}`);
            if (from && to) {
              return to >= from;
            }
          }
          return true;
        },
      )
      .test("toDate", "To date cannot be in the future", function (value) {
        if (this.parent.dateRangeOption === "custom") {
          const to = new Date(`${value.year}-${value.month}-${value.day}`);
          const today = new Date();
          to.setHours(0, 0, 0, 0);
          today.setHours(0, 0, 0, 0);
          return to <= today;
        }
        return true;
      })
      .test(
        "customRange",
        "The date range must be within 31 days",
        function (value) {
          const { dateRangeOption, fromDate } = this.parent;
          if (dateRangeOption === "custom") {
            const from = new Date(
              `${fromDate.year}-${fromDate.month}-${fromDate.day}`,
            );
            const to = new Date(`${value.year}-${value.month}-${value.day}`);
            if (from && to) {
              const diffTime = Math.abs(to.getTime() - from.getTime());
              const diffDays = diffTime / (1000 * 60 * 60 * 24);
              return diffDays <= 31;
            }
          }
          return true;
        },
      ),
  });

  const calculateDates = (option: string) => {
    if (option === "custom") {
      return {
        fromDate: formatDateToInputValue(null),
        toDate: formatDateToInputValue(null),
      };
    }
    const today = new Date();
    let fromDate: Date;
    let toDate: Date = today;

    switch (option) {
      case "yesterday":
        toDate.setDate(today.getDate() - 1);
        fromDate = toDate;
        break;
      case "last7days":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 6);
        break;
      case "last14days":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 13);
        break;
      case "last31days":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 30);
        break;
      default:
        fromDate = new Date(today);
        break;
    }

    return {
      fromDate: formatDateToInputValue(fromDate),
      toDate: formatDateToInputValue(toDate),
    };
  };

  const onSubmit = (values: SelectedDatesInput) => {
    setSelectedDate({
      dateRangeOption: values.dateRangeOption,
      fromDate: new Date(
        `${values.fromDate.year}-${values.fromDate.month}-${values.fromDate.day}`,
      ),
      toDate: new Date(
        `${values.toDate.year}-${values.toDate.month}-${values.toDate.day}`,
      ),
    });
    handlePageChange(true);
  };

  const scrollToField = (fieldName: string) => {
    const element = document.getElementById(fieldName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.focus();
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, values, handleChange, setFieldValue }) => (
          <Form>
            {showErrorSummary && Object.keys(errors).length > 0 && (
              <ErrorSummary id="formErrors">
                <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
                <ErrorSummary.Body>
                  <ErrorSummary.List>
                    {Object.keys(errors).map((fieldName, index) => (
                      <ErrorSummary.Item key={index}>
                        <button
                          className={`anchor-style ${
                            hoveredField === fieldName
                              ? "hovered-color"
                              : "error-color"
                          }`}
                          onMouseEnter={() => setHoveredField(fieldName)}
                          onMouseLeave={() => setHoveredField(null)}
                          key={index}
                          onClick={() => scrollToField(fieldName)}
                        >
                          {errors[fieldName]}
                        </button>
                      </ErrorSummary.Item>
                    ))}
                  </ErrorSummary.List>
                </ErrorSummary.Body>
              </ErrorSummary>
            )}
            <Field name="dateRangeOption">
              {({ field, meta }) => (
                <fieldset>
                  <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                    <h1 className="nhsuk-fieldset__heading">Choose dates</h1>
                  </legend>
                  <div
                    className="nhsuk-hint mb-3"
                    id="record-a-service-h1-hint"
                  >
                    <p>Select a date range for your report</p>
                  </div>
                  <Radios
                    id="dateRangeOption"
                    name="dateRangeOption"
                    error={meta.touched && meta.error ? meta.error : ""}
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      const { fromDate, toDate } = calculateDates(
                        e.target.value,
                      );
                      setFieldValue("fromDate", fromDate);
                      setFieldValue("toDate", toDate);
                      setIsCustomDateSelected(!isCustomDateSelected);
                    }}
                  >
                    <Radios.Radio
                      value="today"
                      checked={values.dateRangeOption === "today"}
                    >
                      Today
                    </Radios.Radio>
                    <Radios.Radio
                      value="yesterday"
                      checked={values.dateRangeOption === "yesterday"}
                    >
                      Yesterday
                    </Radios.Radio>
                    <Radios.Radio
                      value="last7days"
                      checked={values.dateRangeOption === "last7days"}
                    >
                      Last 7 days (includes today)
                    </Radios.Radio>
                    <Radios.Radio
                      value="last14days"
                      checked={values.dateRangeOption === "last14days"}
                    >
                      Last 14 days (includes today)
                    </Radios.Radio>
                    <Radios.Radio
                      value="last31days"
                      checked={values.dateRangeOption === "last31days"}
                    >
                      Last 31 days (includes today)
                    </Radios.Radio>
                    <div className="nhsuk-radios__divider">or</div>
                    <Radios.Radio
                      value="custom"
                      checked={values.dateRangeOption === "custom"}
                    >
                      Select a custom date range up to 31 days
                    </Radios.Radio>
                  </Radios>
                </fieldset>
              )}
            </Field>

            {values.dateRangeOption === "custom" && (
              <div className="radios-dropdown">
                <Field name="fromDate">
                  {({ field, meta }) => (
                    <DateInput
                      id="fromDate"
                      name="fromDate"
                      label="From"
                      hint="For example 15, 3, 2024"
                      error={meta.touched && meta.error ? meta.error : ""}
                      {...field}
                      onChange={(e) => {
                        handleChange({
                          ...e,
                          target: {
                            ...e.target,
                            name: "fromDate",
                          },
                        });
                      }}
                    />
                  )}
                </Field>
                <Field name="toDate">
                  {({ field, meta }) => (
                    <DateInput
                      id="toDate"
                      name="toDate"
                      label="To"
                      hint="For example 15, 3, 2024"
                      error={meta.touched && meta.error ? meta.error : ""}
                      {...field}
                      onChange={(e) => {
                        handleChange({
                          ...e,
                          target: {
                            ...e.target,
                            name: "toDate",
                          },
                        });
                      }}
                    />
                  )}
                </Field>
              </div>
            )}

            <Button
              type="submit"
              onClick={() => setShowErrorSummary(true)}
              disabled={isSubmitting}
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
