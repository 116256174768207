import { Button } from 'nhsuk-react-components';
import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { SignOut } from '../../../components/user/user.functions';
import sessionService from '../../services/session/session.service';
import userService from '../../../components/user/user.service';

function formatTime(seconds: number): string {
    // Round the seconds to the nearest integer
    const totalSeconds = Math.round(seconds);

    // Calculate minutes and seconds
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;

    // Build the formatted string
    let timeString = '';

    if (minutes > 0) {
        timeString += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }

    if (remainingSeconds > 0 || minutes === 0) {
        if (minutes > 0) {
            timeString += ' ';
        }
        timeString += `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    }

    return timeString;
}

const TimeOutWarningThresholdInMinutes = 4;

const TimeOutWarning: React.FC = () => {
    const [isTimeOutWarningVisible, setIsTimeOutWarningVisible] = useState(false);
    const [ishovered, setIsHovered] = useState<boolean>(false);
    const [timeRemainingInSeconds, setTimeRemainingInSeconds] = useState<number>(300);
    const [user, setUser] = useState(userService.getUser());

    useEffect(() => {
        const subscription = userService.subscribe(() => getUser())
        getUser()
        return () => { userService.unsubscribe(subscription); }
    }, []);

    const getUser = async () => {
        setUser(await userService.getUser$());
    }

    useEffect(() => {
        if (user) {
            setInterval(() => {
                const sessionExpirationTime = sessionService.getSessionTimestamp();
                const currentTime = new Date().getTime();
                const timeRemaining = parseInt(sessionExpirationTime) - currentTime;
                const timeRemainingInMinutes = timeRemaining / 1000 / 60;
                if (timeRemainingInMinutes <= TimeOutWarningThresholdInMinutes) {
                    setIsTimeOutWarningVisible(true);
                    setTimeRemainingInSeconds(timeRemaining / 1000);
                } else {
                    setIsTimeOutWarningVisible(false);
                }
                if (timeRemainingInMinutes <= 0) {
                    setIsTimeOutWarningVisible(false);
                    SignOut();
                }
            }, 1000);
        }
    }, [user]);

    if (isTimeOutWarningVisible) {
        return (
            <Modal isOpen={isTimeOutWarningVisible} autoFocus centered>
                <div className="timeout-warning timeout-warning--focused">
                    <p><strong>You're about to be logged out</strong></p>
                    <p>For security reasons, we'll log you out of RAVS in <strong>{formatTime(timeRemainingInSeconds)}</strong></p>
                    <div className="button-container">
                        <div className="button-wrapper">
                            <Button style={{marginBottom: '10px'}} onClick={() => { sessionService.extendSession(); setIsTimeOutWarningVisible(false); }}>
                                Stay logged in
                            </Button>
                        </div>
                        <div className="button-wrapper">
                            <button
                                style={{marginBottom: '10px'}}
                                className={`anchor-style ${ishovered ? "hovered-color" : "default-color"}`}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={() => {
                                    SignOut();
                                }}
                            >
                                Log out
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    } else {
        return null;
    }
};

export default TimeOutWarning;