import React, { useEffect, useState } from 'react';
import { Button, SummaryList } from 'nhsuk-react-components';
import { UserFormValues } from '../user/pages/ManageUsersRoot';
import EmailPreview from './email-preview/email-preview';
import useOktaService from '../../_shared/services/okta/okta.service';
import { NotifyTemplateType } from '../../_shared/shared.enums';
import { Loading } from '../../_shared/components/Loading';
import { useNavigate } from 'react-router-dom';

const OrganisationUserSummary: React.FC<{
  userFormValues: UserFormValues;
  onConfirmAndSend: () => void;
  onChangeField: (fieldName: string) => void;
}> = ({ userFormValues, onConfirmAndSend, onChangeField }) => {
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [emailTemplate, setEmailTemplate] = useState<NotifyTemplateType>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fields = [
    {
      key: 'Name',
      value: `${userFormValues.firstName} ${userFormValues.lastName}`,
      field: 'firstName',
    },
    { key: 'Email address', value: userFormValues.email, field: 'email' },
    {
      key: 'Clinical',
      value: userFormValues.clinician,
      field: 'clinician',
    },
    {
      key: 'Permission level',
      value: userFormValues.permission,
      field: 'permission',
    },
  ];

  useEffect(() => {
    const fetchEmailTemplate = async (email) => {
      try {
        setLoading(true);
        const exists = await useOktaService.checkOktaUserExistence$(email);

        const templateType = exists
          ? NotifyTemplateType.NewUserSignUpWithoutOktaActivation
          : NotifyTemplateType.NewUserSignUpWithOktaActivation;

        setEmailTemplate(templateType);
      } catch (error) {
        console.error('Error checking if user exists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmailTemplate(userFormValues.email);
  }, [userFormValues.email]);

  return (
    <div className="nhsuk-grid-row nhsuk-grid-column-two-thirds">
      <SummaryList>
        {fields.map(({ key, value, field: fieldName }) => (
          <SummaryList.Row key={key}>
            <SummaryList.Key>{key}</SummaryList.Key>
            <SummaryList.Value>{value}</SummaryList.Value>
            <SummaryList.Actions>
              <button
                className={`anchor-style ${hoveredField === fieldName ? 'hovered-color' : 'default-color'}`}
                onMouseEnter={() => setHoveredField(fieldName)}
                onMouseLeave={() => setHoveredField(null)}
                onClick={() => onChangeField(fieldName)}
              >
                Change
                <span className="nhsuk-u-visually-hidden"> {key.toLowerCase()}</span>
              </button>
            </SummaryList.Actions>
          </SummaryList.Row>
        ))}
      </SummaryList>
      <p>{userFormValues.firstName} will be sent this welcome email with information about activating an account:</p>

      {loading && <Loading message={'Loading email preview'} />}

      {!loading && <EmailPreview templateType={emailTemplate} email={userFormValues.email} />}

      <Button className="nhsuk-button" data-module="nhsuk-button" onClick={onConfirmAndSend}>
        Confirm and send
      </Button>
    </div>
  );
};

export default OrganisationUserSummary;
