import React, { useEffect, useState } from 'react'
import usePageDetails from '../../vaccination/hooks/usePageDetails';
import { Loading } from '../../../_shared/components/Loading';
import { BackLink, Button, WarningCallout } from 'nhsuk-react-components';
import { Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import userDbService from '../userDb.service';
import AccessControl from '../../../_shared/components/accessControl/AccessControl';
import { AccountStatusIds, RoleIds } from '../user.enums';
import { AccountStatusUser } from '../user.models';
import usePageSettings from '../../../_shared/hooks/usePageSettings';

export default function ActivateUser() {
  usePageSettings(['service', 'manage-users', 'activate']);
  const navigate = useNavigate();
  const { id } = usePageDetails();
  const [ accountStatusUser, setAccountStatusUser ] = useState<AccountStatusUser>(null);
  const [ saving, setSaving ] = useState<boolean>(false);

  useEffect(() => {
    userDbService.accountStatusUser$(id).then(res => {
      setAccountStatusUser(res);
    });
  }, []);

  const activate = async () => {
    setSaving(true);
    await userDbService.activateUser$(id).finally(() => setSaving(false));
    navigate('/manage-users');
  }

  return (    
    <AccessControl requiredRoles={[RoleIds.LeadAdministrator]}>
      <BackLink asElement="button" onClick={() => navigate('/manage-users/deactivated-users')}>
        Back
      </BackLink>
      
      { accountStatusUser ? 
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">  
            { accountStatusUser.AccountStatusId === AccountStatusIds.Enabled ?
              <div className="nhsuk-warning-callout">
                <WarningCallout.Label>
                  Warning
                </WarningCallout.Label>
                <p>
                  This user is already reactivated!
                </p>
              </div> 
              :
              <>
                <h1 className="nhsuk-heading-l">Reactivate {accountStatusUser.FirstName} {accountStatusUser.LastName}</h1>

                <p>Once you have reactivated {accountStatusUser.FirstName} {accountStatusUser.LastName} ({accountStatusUser.Email}), 
                  they can sign in to NHS Record a vaccination again using their Okta account.</p>

                { saving ? 
                  <Spinner className='float-start'></Spinner> 
                  :
                  <Button onClick={activate}>Reactivate</Button>
                } 
             </> 
            }   
          </div>
        </div>  
        : 
        <Loading />
      }
    </AccessControl>  
  )
}